import React, { useState, useEffect } from "react";
import { Col, Container, Form, Pagination, Row, Table } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import BarraPesquisaNome, {
  removeAccents,
} from "../../atoms/BarraPesquisaNome";
import BarraPesquisaNumero, {
  removeNonNumericChars,
} from "../../atoms/BarraPesquisaNumero";
import BarraPesquisaData from "../../atoms/BarraPesquisaData";
import Icons from "../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { AdvogadoService } from "../../../services/AdmAdvogadoServices/AdvogadoService";
import { ptBR } from "date-fns/locale";
import "./styles.css";

const AdmSiteListaAdvogados = () => {
  const api = new AdvogadoService();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [ordenarPorValidado, setOrdenarPorValidado] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtroNome, setFiltroNome] = useState({
    rawValue: "",
    filteredValue: "",
  });
  const [filtroNumero, setFiltroNumero] = useState({
    rawValue: "",
    formattedValue: "",
  });
  const [filtroData, setFiltroData] = useState("");
  const [filtroStatus, setFiltroStatus] = useState("todos");
  const [listarAdvogados, setListarAdvogados] = useState(null);
  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  useEffect(() => {
    async function fetchAdvogadoList() {
      try {
        const response = await api.postListar(
          "nome",
          "data",
          "telefone",
          "validado",
          "dataCadastro",
          "advogadoRecusado"
        );
        const sorted = response.data.slice().sort((a, b) => {
          if (ordenarPorValidado) {
            return a.validado && !b.validado ? -1 : 1;
          } else {
            return a.validado && !b.validado ? 1 : -1;
          }
        });
        setUserData({ data: sorted });
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    }
    fetchAdvogadoList();
  }, [ordenarPorValidado]);

  const handleValidar = (userId) => {
    const url = `/adm/site/advogados/validar/${userId}`;
    navigate(url);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Data inválida";
    try {
      return format(
        utcToZonedTime(parseISO(dateString), fusoHorario),
        "dd/MM/yyyy",
        { locale: ptBR }
      );
    } catch (error) {
      console.error("Erro ao formatar data:", error);
      return "Data inválida";
    }
  };

  const itemsPerPage = 10;
  const totalPagesFiltered = userData
    ? Math.ceil(
        userData.data.filter((user) => {
          switch (filtroStatus) {
            case "validado":
              return user.validado;
            case "pendente":
              return !user.validado && !user.advogadoRecusado;
            case "recusado":
              return user.advogadoRecusado;
            default:
              return true;
          }
        }).length / itemsPerPage
      )
    : 1;

  const renderTableRows = () => {
    if (!userData) return null;

    let filteredData = userData.data;

    if (filtroNome.filteredValue) {
      filteredData = filteredData.filter((user) =>
        removeAccents(user.nome.toLowerCase()).includes(
          filtroNome.filteredValue
        )
      );
    }

    if (filtroNumero.rawValue) {
      filteredData = filteredData.filter((user) =>
        removeNonNumericChars(user.telefone).includes(filtroNumero.rawValue)
      );
    }

    if (filtroData) {
      filteredData = filteredData.filter((user) =>
        user.dataCadastro.includes(filtroData)
      );
    }

    switch (filtroStatus) {
      case "validado":
        filteredData = filteredData.filter((user) => user.validado);
        break;
      case "pendente":
        filteredData = filteredData.filter(
          (user) => !user.validado && !user.advogadoRecusado
        );
        break;
      case "recusado":
        filteredData = filteredData.filter((user) => user.advogadoRecusado);
        break;
      default:
        break;
    }

    if (filteredData.length === 0) return null;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredData.slice(startIndex, endIndex).map((user) => (
      <tr key={user.id} className="align-items-center table-container">
        <td style={{ width: "40%" }}>
          <Paragraph text={user.nome} />
        </td>
        <td style={{ width: "22%" }}>
          <Paragraph text={formatDate(user.dataCadastro)} />
        </td>
        <td style={{ width: "23%" }}>
          <Paragraph text={user.telefone} />
        </td>
        <td style={{ width: "15%" }}>
          {user.advogadoRecusado ? (
            <Btn
              type="danger-sm"
              text="Recusado"
              onTouchable={() => handleValidar(user.id)}
            />
          ) : user.validado ? (
            <Btn
              type="secondary-sm"
              text="Validado"
              onTouchable={() => handleValidar(user.id)}
            />
          ) : (
            <Btn
              type="primary-sm"
              text="Pendente"
              onTouchable={() => handleValidar(user.id)}
            />
          )}
        </td>
      </tr>
    ));
  };

  const handleFiltroNomeChange = ({ rawValue, filteredValue }) => {
    setFiltroNome({ rawValue, filteredValue });
    setCurrentPage(1);
  };

  const handleFiltroNumeroChange = ({ rawValue, formattedValue }) => {
    setFiltroNumero({ rawValue, formattedValue });
    setCurrentPage(1);
  };

  const handleFiltroDataChange = (event) => {
    setFiltroData(event.target.value);
    setCurrentPage(1);
  };

  const handleFiltroStatusChange = (event) => {
    setFiltroStatus(event.target.value);
    setCurrentPage(1);
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    if (cleanedNumber.length === 11) {
      return `(${cleanedNumber.slice(0, 2)}) ${cleanedNumber.slice(
        2,
        7
      )}-${cleanedNumber.slice(7)}`;
    } else {
      return phoneNumber;
    }
  }

  const exportToCSV = () => {
    if (!userData) return null;

    let filteredData = userData.data;

    if (filtroNome.filteredValue) {
      filteredData = filteredData.filter((user) =>
        removeAccents(user.nome.toLowerCase()).includes(
          filtroNome.filteredValue
        )
      );
    }

    if (filtroNumero.rawValue) {
      filteredData = filteredData.filter((user) =>
        removeNonNumericChars(user.telefone).includes(filtroNumero.rawValue)
      );
    }

    if (filtroData) {
      filteredData = filteredData.filter((user) =>
        (user.dataCadastro).includes(filtroData.toLowerCase())
      );
    }

    // Filtros de acordo com o status
    switch (filtroStatus) {
      case "validado":
        filteredData = filteredData.filter((user) => user.validado);
        break;
      case "pendente":
        filteredData = filteredData.filter(
          (user) => !user.validado && !user.advogadoRecusado
        );
        break;
      case "recusado":
        filteredData = filteredData.filter((user) => user.advogadoRecusado);
        break;
      default:
        break;
    }

    // Se não houver dados filtrados, retorna null
    if (filteredData.length === 0) return null;

    const csvData = filteredData.map((item, index) => ({
      nome: item.nome?.replace(',', '').replace(/\n/g,''),
      cpf: item.cpf?.replace(',', '').replace(/\n/g,''),
      data: formatDate(item.dataCadastro),
      telefone: formatPhoneNumber(item.telefone),
      conta: item.advogadoRecusado ? 'Recusado' : item.validado ? 'Validado': 'Pendente',
      planoDisponivel: item.planoDisponivel ? 'Disponível' : 'N/A'
    }));

    const csvContent = [
      [
        "Nome",
        "CPF",
        "Data",
        "Telefone",
        "Conta",
        "Plano"
      ],
      console.log("csv data esta aqui ->", csvData),
      ...csvData.map((item) =>
        [
          item.nome,
          item.cpf,
          item.data,
          item.telefone,
          item.conta,
          item.planoDisponivel
        ].join(",")
      ),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={4} className="d-flex gap-2 exportar-button">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
        <Col>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>Telefone</th>
                <th>Status/Editar</th>
              </tr>
              <tr>
                <th>
                  <BarraPesquisaNome
                    value={filtroNome}
                    onChange={handleFiltroNomeChange}
                  />
                </th>
                <th>
                  <BarraPesquisaData
                    value={filtroData}
                    onChange={handleFiltroDataChange}
                  />
                </th>
                <th>
                  <BarraPesquisaNumero
                    value={filtroNumero}
                    onChange={handleFiltroNumeroChange}
                  />
                </th>
                <th>
                  <Form.Control
                    as="select"
                    value={filtroStatus}
                    onChange={handleFiltroStatusChange}
                    style={{
                      backgroundImage:
                        'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 3.451 6.659c-.566-.661-.106-1.659.753-1.659h8.284c.86 0 1.32.998.753 1.659l-3.796 4.48c-.566.661-1.512.661-2.078 0z"/></svg>\')',
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 0.75rem center",
                      backgroundSize: "16px 12px",
                    }}
                  >
                    <option value="todos">Todos</option>
                    <option value="validado">Validado</option>
                    <option value="pendente">Pendente</option>
                  </Form.Control>
                </th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </Col>
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-center"
        >
          <Pagination size="sm">
            {Array.from({ length: totalPagesFiltered }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmSiteListaAdvogados;
