import { useState, useEffect } from "react";
import "./styles.css";
import { Col, Container, Row, FormControl } from "react-bootstrap";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import Subtitle from "../../atoms/Subtitle";
import { ptBR } from "date-fns/locale";
import { PerfilCliModal } from "../../molecules/PerfilCliModal";
import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const HistoricoConsultasAdv = () => {
  const fusoHorario = 'Etc/UTC';
  const api = new ConsultaService();
  const id = sessionStorage.getItem("id");

  const [consultas, setConsultas] = useState([]);
  const [visibleItems, setVisibleItems] = useState(4);
  const [showMore, setShowMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [valorId, setValorId] = useState();
  const [valor, setValor] = useState([]);
  const [fotoCliente, setFotoCliente] = useState("");
  const [filterName, setFilterName] = useState("");

  const handleOpenModal = (clientId) => {
    const consultasCliente = consultas.find(cliente => cliente.cli_id === clientId);

    if (consultasCliente && consultasCliente.consultas.length > 0) {
      const consultaId = consultasCliente.consultas[0].consulta_id;

      const clienteEncontrado = consultas.find(consultas => consultas.cli_id === clientId);
      setValor(clienteEncontrado.consultas);
      setFotoCliente(clienteEncontrado.clienteFoto);

      setValorId(clientId);

      setShowModal(true);
    } else {
      console.log("Nenhuma consulta encontrada para o cliente com ID:", clientId);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchAndFilterResults = async () => {
      try {
        if (filterName.trim() === '') {
          const response = await api.historicoConsultas();
          if (response.data && Array.isArray(response.data)) {
            const historicoFormatado = response.data.map(cliente => ({
              cli_id: cliente.cli_id,
              clienteFoto: cliente.clienteFoto, // Adicione a foto do cliente
              consultas: cliente.consultas.map(consulta => ({
                consulta_id: consulta.consulta_id,
                status_consulta: consulta.status_consulta,
                Hora: consulta.Hora,
                Data: consulta.Data,
                nome_Cliente: consulta.nome_Cliente,
              })),
            }));
            setConsultas(historicoFormatado);
          }
        } else {
          const filteredConsultas = consultas.filter((cliente) =>
            cliente.consultas.some((consulta) =>
              consulta.nome_Cliente.toLowerCase().includes(filterName.toLowerCase().trim())
            )
          );

          setConsultas(filteredConsultas);
        }
      } catch (error) {
        console.error("Erro durante o processo:", error);
      }
    };

    fetchAndFilterResults();
  }, [filterName, id]);

  useEffect(() => {
    async function fetchHistoricoConsultas() {
      try {
        const response = await api.historicoConsultas();
        if (response.data && Array.isArray(response.data)) {
          const historicoFormatado = response.data.map(cliente => ({
            cli_id: cliente.cli_id,
            clienteFoto: cliente.clienteFoto, // Adicione a foto do cliente
            consultas: cliente.consultas.map(consulta => ({
              consulta_id: consulta.consulta_id,
              status_consulta: consulta.status_consulta,
              Hora: consulta.Hora,
              Data: consulta.Data,
              nome_Cliente: consulta.nome_Cliente,
            })),
          }));
          setConsultas(historicoFormatado);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchHistoricoConsultas();
  }, [id]);

  const exportToCSV = () => {
    const csvData = consultas.map((cliente) => {
      return cliente.consultas.map((consulta) => ({
        Nome: consulta.nome_Cliente,
        Data: cliente.consultas.length > 0 ? formatDate(cliente.consultas[cliente.consultas.length - 1].Data) : '',
        Hora: cliente.consultas.length > 0 ? formatTime(cliente.consultas[cliente.consultas.length - 1].Hora) : '',
      }));
    }).flat();

    const csvContent = [
      ["Nome", "Data", "Hora"],
      ...csvData.map((item) => [item.Nome, item.Data, item.Hora]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleVerMaisClick = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  function formatTime(time) {
    if (time && time.length >= 4) {
      const formattedTime = `${time.substring(0, 2)}:${time.substring(2)}`;
      return formattedTime;
    }
    return "";
  }

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      'dd/MM/yyyy',
      { locale: ptBR }
    );
  };

  return (
    <Container>
      <PerfilCliModal
        show={showModal}
        userData={valor}
        onHide={handleCloseModal}
        cli_id={valorId}
        fotoCliente={fotoCliente}
      />
      <Row className="d-flex align-items-center justify-content-center extrato-container">
        <Col>
          <Subtitle text="Histórico de consultas" />
        </Col>
        <Col className="d-flex text-end align-items-center ">
          <Col lg={10} className="cursor" onClick={exportToCSV}>
            <Paragraph text="EXPORTAR DADOS" />
          </Col>
          <Col lg={2} className="cursor" onClick={exportToCSV}>
            <Icons type="exportar" />
          </Col>
        </Col>

        <Row className="mt-3">
          <Col className="d-flex">
            <FormControl
              type="text"
              placeholder="Pesquise por nome"
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Row>

      <Row className="d-flex justify-content-center mt-2">
        {consultas
          .slice(0, showMore ? consultas.length : visibleItems)
          .map((cliente, index) => (
            <Row key={index} className="w-75 d-flex align-items-center justify-content">
              <Col lg={2}>
                <AvatarPerfil
                  type="B"
                  base64={cliente.clienteFoto || ''}
                  width="4rem"
                  height="4rem"
                  padding="0.1rem"
                  margin="1px"
                  display="none"
                />
              </Col>
              <Col>
                <Row className="mt-3">
                  <Col key={index} className="d-flex">
                    <Col sm={12} lg={7}>
                      {cliente.consultas.length > 0 ? cliente.consultas[cliente.consultas.length - 1].nome_Cliente : ''}
                    </Col>
                    <Col sm={12} lg={5} className="d-flex gap-4">
                      <Col>
                        {cliente.consultas.length > 0 ? formatDate(cliente.consultas[cliente.consultas.length - 1].Data) : ''}
                      </Col>
                      <Col>
                        {cliente.consultas.length > 0 ? formatTime(cliente.consultas[cliente.consultas.length - 1].Hora) : ''}
                      </Col>
                    </Col>
                    <Col lg={4} className="text-center mt-2">
                      <Btn
                        text="Ver detalhes"
                        type="secondary-sm"
                        onTouchable={() => handleOpenModal(cliente.cli_id)}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              <hr />
            </Row>
          ))}
      </Row>
      {visibleItems < consultas.length && (
        <Col lg={12} className="d-flex justify-content-center mt-3">
          <Btn
            type="secondary-sm"
            isSubmit={false}
            onTouchable={handleVerMaisClick}
            text={showMore ? "Ver menos" : "Ver mais"}
          />
        </Col>
      )}
    </Container>
  );
};
