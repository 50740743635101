import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import { useEffect, useState } from "react";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { FinanceiroService } from "../../../services/ClienteServices/FinanceiroService";
import { Toasts } from "../../utils/Toasts";
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import functions from "../../utils/functions";
import DropdownBtnParcelas from "../../atoms/DropdownBtnParcelas";
import { SairAgendamentoModal } from "../SairAgendamentoModal";
import { useNavigate } from "react-router-dom";
import './styles.css';

export const PagamentoParticular = ({ adv_id, add_id, valorConsulta, changeValues, handleBack, comentario }) => {
  const navigate = useNavigate();
  const id = sessionStorage.getItem("id");
  const api = new FinanceiroService();

  const [parcela, setParcela] = useState(1);
  const [qtdParcela, setQtdParcela] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [taxas, setTaxas] = useState(null);

  const [valorConsultaComTaxa, setValorConsultaComTaxa] = useState(0);
  const [taxaDeParcelamento, setTaxaDeParcelamento] = useState(0);
  const [valorParcela, setValorParcela] = useState(0);

  useEffect(() => {
    const fetchTaxas = async () => {
      try {
        const response = await api.taxasCredito();
        if (response && response.data && response.data.response) {
          setTaxas(response.data.response);
        } else {
          console.error("Resposta inválida da API de taxas:", response);
        }
      } catch (error) {
        console.error("Erro ao obter taxas:", error);
      }
    };

    fetchTaxas();
  }, []);


  const verifyEmissoraCartao = () => {
    return true;
  };


  const calcularValorComTaxa = (valorConsulta, taxa, operacao) => {
    // Definindo a equação: x - (x / 100 * 1.99) - 0.49 = 400
   // Para simplificar, vamos definir uma função f(x) que iguala a zero a expressão da esquerda da equação
   const f = (x) => x - (x / 100 * taxa) - operacao - valorConsulta;

   // Utilizando o método da bisseção para encontrar a raiz da função f(x)
   // Definindo um intervalo inicial onde a raiz deve estar (pode ajustar conforme necessário)
   let a = valorConsulta;  // Valor mínimo possível de x
   let b = valorConsulta * 1.1;  // Valor máximo possível de x

   // Tolerância desejada para o resultado
   const tolerance = 1e-4;

   // Iterações máximas permitidas (para evitar loop infinito)
   const maxIterations = 1000;

   let x = 0;  // Inicialização da variável x

   // Método da bisseção
   for (let i = 0; i < maxIterations; i++) {
       x = (a + b) / 2;  // Ponto médio do intervalo

       // Calculando o valor de f(x)
       let fx = f(x);

       // Verificando se a raiz foi encontrada com a tolerância desejada
       if (Math.abs(fx) < tolerance) {
           break;
       }

       // Atualizando o intervalo [a, b] com base no valor de f(x)
       if (fx > 0) {
           // A raiz está no intervalo [a, x]
           b = x;
       } else {
           // A raiz está no intervalo [x, b]
           a = x;
       }
   }

   // Retornando o valor de x encontrado
   return x;
 }

 const handleParcelaSelect = (parcela) => {
   if (taxas) {
     let valorConsultaComTaxa = 0;
     let novaTaxaDeParcelamento = 0;
     let novoValorParcela = 0;

     if (parcela === 1) {
       novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_1x);
       valorConsultaComTaxa = calcularValorComTaxa(valorConsulta, novaTaxaDeParcelamento, parseFloat(taxas.taxaOperacao));
     } else if (parcela >= 2 && parcela <= 6) {
       novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_2a6x);
       valorConsultaComTaxa = calcularValorComTaxa(valorConsulta, novaTaxaDeParcelamento, parseFloat(taxas.taxaOperacao));
     } else if (parcela >= 7 && parcela <= 12) {
       novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_7a12x);
       valorConsultaComTaxa = calcularValorComTaxa(valorConsulta, novaTaxaDeParcelamento, parseFloat(taxas.taxaOperacao));
     }

     novoValorParcela = valorConsultaComTaxa / parcela;
     valorConsultaComTaxa = parseFloat(valorConsultaComTaxa.toFixed(2));
     novoValorParcela = parseFloat(novoValorParcela.toFixed(2));

     setValorConsultaComTaxa(valorConsultaComTaxa);
     setTaxaDeParcelamento(novaTaxaDeParcelamento);
     setValorParcela(novoValorParcela);
   } else {
     console.error("Taxas não disponíveis no momento da seleção da parcela.");
   }
 };

  const handleQtdClick = (data) => {
    setParcela(data.value);
  };

  const generateQtdParcelaOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ label: `${i} parcela${i !== 1 ? "s" : ""}`, value: i });
    }
    setQtdParcela(options);
  };

  const enviarDados = (values) => {
    if (
      valorConsultaComTaxa === 0 ||
      valorConsultaComTaxa === null ||
      typeof valorConsultaComTaxa === "undefined"
    ) {
      let optionsToast = {
        code: "102",
        message: "Selecione o número de parcelas.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return;
    }

    setIsLoading(true);
    api
      .realizarPagamentoParticular(
        adv_id,
        add_id,
        parcela,
        values.cartaoNome,
        values.cartaoNumero,
        values.dataExpiracao,
        values.cartaoCcv,
        // values.telefone,
        // values.cpf,
        // values.cep,
        // values.enderecoNumero,
        comentario,
        valorConsultaComTaxa
      )
      .then((data) => {
        if (data.status === true) {
          setIsLoading(false);
          changeValues();
          // let optionsToast = {
          //   code: data.mensagem.codigo,
          //   message: data.mensagem.mensagem,
          //   visible: true,
          // };
          // setOptionsToast(optionsToast);
          // setTimeout(() => {
            // navigate("/ConsultasCli");
          // }, 1500);
        } else {
          setHasError(true)
          setMessage(data.mensagem.mensagem)
          // let optionsToast = {
          //   code: data.mensagem.codigo,
          //   message: data.mensagem.mensagem,
          //   visible: true,
          // };
          // setOptionsToast(optionsToast);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    generateQtdParcelaOptions();
  }, []);

  const handleBackPage = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const schema1 = Yup.object().shape({
    // telefone: Yup.string().required("O campo telefone é obrigatório"),
    // cpf: Yup.string()
    //   .test("valid-cpf", "CPF inválido", functions.validarCPF)
    //   .required("CPF é obrigatório"),
    // cep: Yup.string().required("O campo CEP é obrigatório"),
    // enderecoNumero: Yup.string().required("O campo número é obrigatório"),
    cartaoNumero: Yup.string()
      .min(15, "Número do cartão inválido")
      // .max(16)
      .test(
        "valid-cartao-numero",
        "Número do cartão inválido",
        validaNumeroCartao
      )
      .required("O campo número do cartão é obrigatório"),
    cartaoNome: Yup.string().required("O campo nome do cartão é obrigatório"),
    dataExpiracao: Yup.string()
      .matches(/^\d{2}\/\d{2}$/, "Data de expiração inválida")
      .test(
        "valid-cartao-validade",
        "Data de expiração inválida",
        validaDataValidade
      )
      .required("O campo data de expiração é obrigatório"),
    cartaoCcv: Yup.string()
      .min(3, "CVV inválido")
      .max(3)
      .required("O campo CVV do cartão é obrigatório"),
  });

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  function validaNumeroCartao(numero = "") {
    var soma = 0;

    //numero = numero.replace(/\D/g, "");

    var digitos = numero.split("").reverse();
    for (var i = 0; i < digitos.length; i++) {
      var digito = parseInt(digitos[i]);
      if (i % 2 !== 0) {
        digito *= 2;
        if (digito > 9) {
          digito -= 9;
        }
      }
      soma += digito;
    }
    return soma % 10 === 0;
  }

  function validaDataValidade(dataValidade = "") {
    var partes = dataValidade.split("/");
    var mes = parseInt(partes[0], 10);
    var ano = parseInt(partes[1], 10) + 2000; // Adiciona 2000 para converter o ano de dois dígitos em quatro dígitos

    var dataAtual = new Date();
    var mesAtual = dataAtual.getMonth() + 1; // Os meses são indexados a partir de 0 em JavaScript
    var anoAtual = dataAtual.getFullYear();

    // O cartão é válido se o ano for maior que o ano atual, ou se o ano for igual ao ano atual e o mês for maior ou igual ao mês atual
    return ano > anoAtual || (ano == anoAtual && mes >= mesAtual);
  }

  return (
    <Formik
      key={1}
      validationSchema={schema1}
      onSubmit={(values) => {
        // const telefoneSemMascara = functions.removeMascara(values.telefone);
        // const cpfSemMascara = functions.removeMascara(values.cpf);
        // const cepSemMascara = functions.removeMascara(values.cep);
        const valuesSemMascara = {
          ...values,
          valorConsultaComTaxa,
          parcela,
          // telefone: telefoneSemMascara,
          // cpf: cpfSemMascara,
          // cep: cepSemMascara,
        };

        // console.log(values)
        enviarDados(valuesSemMascara);
      }}
      initialValues={{
        id: "",
        cartaoNome: "",
        cartaoNumero: "",
        dataExpiracao: "",
        cartaoCcv: "",
        // telefone: "",
        // cpf: "",
        // cep: "",
        // enderecoNumero: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleReset,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Container className="d-flex ">
          <Form noValidate onSubmit={handleSubmit} className="">
            <Row className="pl-0 mt-5 d-felx align-items-center">
              <Col className="d-flex flex-column pl-0">
                <Col lg={6}>
                  <Subtitle text="Número de parcelas:" />

                  <Form.Group controlId="parcelas">
                    <FloatingLabel>
                      <Col className="render-index">
                        <DropdownBtnParcelas
                          text="Selecione as parcelas"
                          buttonType="primary"
                          options={qtdParcela}
                          onPress={(data) => {
                            handleParcelaSelect(data.value);
                            handleQtdClick(data);
                          }}
                        />
                      </Col>
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.parcelas}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column pb-2 bg-aviso-box rounded gap-2"
                  >
                    <Col className="pl-0 d-flex ps-3  mt-3 justify-content-between">
                      <Subtitle text="Valor da consulta" />
                      <Subtitle text={`R$ ${valorConsultaComTaxa ?? "-"}`} />
                    </Col>
                    {/* <Col className="d-flex ps-3 pe-3 justify-content-between">
                      <Paragraph text="Taxa de processamento" />
                      <Paragraph
                        text={` R$ ${taxas?.taxaOperacao ?? "-"} + ${
                          taxaDeParcelamento ?? "-"
                        }%`}
                      />
                    </Col> */}
                    <Col className="d-flex bg-warning p-3 rounded justify-content-between">
                      <Paragraph text="Valor da parcela" />
                      <Paragraph
                        text={`${parcela ?? "-"}x de R$ ${
                          valorParcela ?? "-"
                        } `}
                      />
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row className="pl-0 mt-5">
              <Col className="pl-0" lg={12}>
                <Subtitle text="Dados para pagamento" />
                <ParagraphSmall text="Complete o formulário abaixo para realizar o pagamento" />
              </Col>

              <Col className="pl-0 pr-3" lg={6}>
                <Form.Group controlId="cartaoNome">
                  <FloatingLabel label={"Nome do titular do cartão"}>
                    <Form.Control
                      maxLength={80}
                      className={`input-register-login-facj ${
                        touched.cartaoNome && errors.cartaoNome
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      name="cartaoNome"
                      value={values.cartaoNome}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          /^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)
                        ) {
                          handleChange(e);
                        }
                      }}
                      isValid={touched.cartaoNome && !errors.cartaoNome}
                      placeholder="Nome do cartão"
                    />
                    {touched.cartaoNome && errors.cartaoNome && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cartaoNome}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.cartaoNome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="cartaoNumero">
                  <FloatingLabel label={"Número do cartão"}>
                    <Form.Control
                      maxLength={80}
                      className={`input-register-login-facj ${
                        touched.cartaoNumero && errors.cartaoNumero
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      name="cartaoNumero"
                      value={values.cartaoNumero}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          /^[0-9]*$/.test(inputValue) &&
                          inputValue.length <= 16
                        ) {
                          handleChange(e);
                        }
                      }}
                      isValid={touched.cartaoNumero && !errors.cartaoNumero}
                      placeholder="Numero do cartão"
                    />
                    {touched.cartaoNumero && errors.cartaoNumero && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cartaoNumero}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.cartaoNumero}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col className="pl-0 pr-3" lg={6}>
                <Form.Group className="mb-3" controlId="dataExpiracao">
                  <FloatingLabel label={"Data de expiração"}>
                    <InputMask
                      mask="99/99"
                      value={values.dataExpiracao}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.dataExpiracao && errors.dataExpiracao
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="dataExpiracao"
                          value={values.dataExpiracao}
                          onChange={handleChange}
                          isValid={
                            touched.dataExpiracao && !errors.dataExpiracao
                          }
                          placeholder="Data de Expiração"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.dataExpiracao && errors.dataExpiracao && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.dataExpiracao}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="cartaoCcv">
                  <FloatingLabel label={"CVV do cartão"}>
                    <Form.Control
                      maxLength={80}
                      className={`input-register-login-facj ${
                        touched.cartaoCcv && errors.cartaoCcv
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      name="cartaoCcv"
                      value={values.cartaoCcv}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          /^[0-9]*$/.test(inputValue) &&
                          inputValue.length <= 3
                        ) {
                          handleChange(e);
                        }
                      }}
                      isValid={touched.cartaoCcv && !errors.cartaoCcv}
                      placeholder="Cvv do cartão"
                    />
                    {touched.cartaoCcv && errors.cartaoCcv && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cartaoCcv}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  {/* <Form.Control.Feedback type="invalid" tooltip>
                    Teste
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <div style={{position: 'relative'}}>
                  {
                  hasError && <div class='bg-error-api-tooltip'>
                  {message}
                  </div>
                  }
                </div>
                
              </Col>
              
              {/* <Col lg={6}>
                <Form.Group controlId="telefone">
                  <FloatingLabel label={"Telefone"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.telefone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.telefone && errors.telefone
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="telefone"
                          value={values.telefone}
                          onChange={handleChange}
                          isValid={touched.telefone && !errors.telefone}
                          placeholder="Telefone"
                          {...inputProps}
                        />
                      )}
                    </InputMask>

                    {touched.telefone && errors.telefone && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.telefone}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col> */}

              {/* <Col lg={6}>
                <Form.Group controlId="cep">
                  <FloatingLabel label={"Cep"}>
                    <InputMask
                      mask="99999-999"
                      value={values.cep}
                      onChange={(e) => {
                        handleChange(e); // Atualize o valor do campo CEP no estado do Formik
                      }}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          maxLength={80}
                          className={`input-register-login-facj ${
                            touched.cep && errors.cep ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cep"
                          value={values.cep}
                          isValid={touched.cep && !errors.cep}
                          placeholder="Cep"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cep && errors.cep && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cep}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.cep}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="enderecoNumero">
                  <FloatingLabel label={"Numero residencial"}>
                    <Form.Control
                      maxLength={80}
                      className={`input-register-login-facj ${
                        touched.enderecoNumero && errors.enderecoNumero
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      name="enderecoNumero"
                      value={values.enderecoNumero}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          /^[0-9]*$/.test(inputValue) &&
                          inputValue.length <= 15
                        ) {
                          handleChange(e);
                        }
                      }}
                      isValid={touched.enderecoNumero && !errors.enderecoNumero}
                      placeholder="Endereço"
                    />
                    {touched.enderecoNumero && errors.enderecoNumero && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.enderecoNumero}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.enderecoComplemento}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>  */}
            </Row>

            <Row className="d-flex flex-column">
              {/* <Col className="my-4">
                <hr className="border-warning border-3 mb-5 mt-5" />
                <Subtitle text="Explique sobre seu caso" />
                <Paragraph
                  text="Para melhor atendimento, precisamos entender o seu caso. 
                Pedimos que preencha o seguinte formulário ou descreva o seu caso, 
                assim o seu advogado(a) terá mais informações para a sua consulta. "
                />
              </Col>
              <Col
                style={{ width: "100%", height: "200px", overflowY: "scroll" }}
              >
                <Form className="">
                  <Form.Group>
                    <Col className="text-center">
                      <Form.Control
                        as="textarea"
                        maxLength={1000}
                        style={{ height: "12rem" }}
                        type="text"
                        name="resposta"
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        placeholder="Digite aqui."
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Col> */}

              <Col lg={6} sm={12} className="d-lg-flex column-gap-2 pl-0 mt-5 pg-step-buttons">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Voltar"
                  onTouchable={handleBack}
                />
                <Btn
                  type={"secondary-load"}
                  isLoading={isLoading}
                  isSumbit={true}
                  text="Realizar Pagamento"
                />
                {!verifyEmissoraCartao() && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    Transação não autorizada pela emissora do cartão
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>

            <Toasts
              code={optionsToast.code}
              message={optionsToast.message}
              visible={optionsToast.visible}
              setDefaultOptions={fecharToast}
            />
          </Form>
          <SairAgendamentoModal show={showModal} onHide={handleCloseModal} />
        </Container>
      )}
    </Formik>
  );
};
