import React, { useEffect, useState, useCallback } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { PerfilService } from '../../../services/AdvogadoServices/PerfilService';
import { Container, Row, Col, FloatingLabel, Form, FormControl, FormLabel } from 'react-bootstrap';
import Btn from '../../atoms/Button';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { Toasts } from "../../utils/Toasts";
import functions from '../../utils/functions';


import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';




export const PerfilAdvogadoForms = ({ data }) => {

  const api = new PerfilService();

  const [defaultNome, setDefaultNome] = useState('');
  const [defaultEmail, setDefaultEmail] = useState('');
  const [defaultCelular, setDefaultCelular] = useState('');
  const [defaultResumo, setDefaultResumo] = useState('');

  const [controlForm, setControlForm] = useState(false)


  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const [type2, setType2] = useState('password');
  const [icon2, setIcon2] = useState(eyeOff);


  const id = sessionStorage.getItem('id');

  const [defaultAdvDadosChange, setDefaultAdvDadosChange] = useState();


  const [tokenRecaptcha, setTokenRecaptcha] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();


  let defaultOptionsToast = {
    code: '',
    message: '',
    visible: false
  }

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const ProfilePhoto = () => {
    const [newPicture, setNewPicture] = "";

    const handleProfile = (e) => {
      // Selecionando o arquivo
      const file = e.target.files[0];

      // Criando um objeto FileReader
      const reader = new FileReader();

      // Adicionamos um evento para
      // escutar o Reader
      reader.addEventListener(
        "load",
        () => {
          //  Quando carregado,
          // reader.result retornará
          // o objeto convertido em Base64
          setNewPicture(reader.result);
        },
        false
      );

      // Caso file esteja populado
      // dispara a função.
      if (file) {
        reader.readAsDataURL(file);
      }
    };

    return (
      <input
        type="file"
        name="profile"
        accept="image/*"
        onChange={(e) => handleProfile(e)}
      />
    );
  };



  function fecharToast() {
    let defaultOptionsToast = {
      code: '',
      message: '',
      visible: false
    }
    setOptionsToast(defaultOptionsToast)
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setTokenRecaptcha(await executeRecaptcha('submit'));
  }, [executeRecaptcha]);



  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);


  useEffect(() => {
    setDefaultNome(data.data.dadosAdvogado.nome);
    let celularComMascara = functions.aplicarMascara(data.data.dadosAdvogado.celular);
    setDefaultCelular(celularComMascara);
    setDefaultEmail(data.data.dadosAdvogado.email);
    setDefaultResumo(data.data.dadosAdvogado.resumo);
    setDefaultAdvDadosChange(data.data.dadosAdvogado);

    if (data.data.dadosAdvogado.nome) {
      sessionStorage.setItem('nome', data.data.dadosAdvogado.nome);

    }
  }, [data]);


  useEffect(() => {
    if (defaultCelular != null || defaultEmail != null || defaultNome != null || defaultResumo != null) {
      setControlForm(true)
    }
  }, [defaultCelular, defaultEmail, defaultNome, defaultResumo]);


  const Schema1 = yup.object().shape({
    nome: yup
      .string()
      .required('O nome é obrigatório')
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, 'Por favor, digite apenas letras e acentos')
      .max(80, 'Máximo de caracteres excedido')
      .min(3, 'Mínimo de caracteres não detectado'),
    email: yup.string().required('Email é obrigatório').email('Formato de e-mail inválido.'),
    celular: yup
     .string()
     .test('celular', 'O Celular deve conter exatamente 11 dígitos', (value) => {
       if (value) {
         return /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
       }
     })
    ,
    resumo: yup.string().required('Resumo é obrigatório').
      min(5, 'Mínimo de caracteres não detectado').
      max(800, 'Máximo de 800 caracteres excedido')
  });


  const handleAdvDadosChange = () => {
    api.postPerfilAdv(tokenRecaptcha)
      .then((data) => {
        setDefaultAdvDadosChange(data.data.dadosAdvogado)
      })
      .catch((error) => {
      });
  }


  const handleSubmit = (values) => {
    api
      .postAlteraDadosPessoaisAdvogado(
        values.nome,
        values.celular,
        values.email,
        values.resumo,
        tokenRecaptcha
      )
      .then((data) => {
        console.log(data);
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        if (data.mensagem.codigo) {
          handleAdvDadosChange();
        }
        setOptionsToast(optionsToast);
      });
  };


  const renderForm = () => {
    return (
      <Container className="mt-3 p-0">
        <Formik
          validationSchema={Schema1}
          initialValues={{
            nome: defaultNome,
            celular: defaultCelular,
            email: defaultEmail,
            resumo: defaultResumo,
          }}
          onSubmit={(values) => {
            // Salva as informações do formulário ao enviar
            setDefaultNome(values.nome);
            setDefaultEmail(values.email);
            setDefaultResumo(values.resumo);

            // Remove a máscara do número de celular
            const celularSemMascara = functions.removeMascara(values.celular);

            // Atualiza o valor do campo celular no objeto de valores
            const valuesSemMascara = {
              ...values,
              celular: celularSemMascara,
            };

            handleSubmit(valuesSemMascara);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="">
                <Col lg={4} className="pb-3">
                  <FloatingLabel controlId="nome" label="Nome">
                    <Field
                      className={`form-control ${touched.nome && errors.nome ? 'is-invalid' : ''}`}
                      type="text"
                      name="nome"
                      value={values.nome}
                      onChange={handleChange}
                      isValid={touched.nome && !errors.nome}
                      placeholder="Nome"
                    />
                    {touched.nome && errors.nome && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.nome}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.nome}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={4} className="pb-3">
                  <FloatingLabel controlId="celular" label="Telefone">
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.celular}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <FormControl
                          as="input"
                          className={`form-control ${touched.celular && errors.celular ? 'is-invalid' : ''}`}
                          type="text"
                          name="celular"
                          value={values.celular}
                          isValid={touched.celular && !errors.celular}
                          placeholder="Celular"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.celular && errors.celular && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.celular}
                      </Form.Control.Feedback>
                    )}

                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.celular}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={4} className="pb-3">
                  <FloatingLabel controlId="email" label="Email">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched.email && errors.email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={8} className="pb-3">
                  <FloatingLabel controlId="resumo" label="Resumo">
                    <Field as="textarea"
                      style={{ height: "10rem" }}
                      type="resumo"
                      id="resumo"
                      name="resumo"
                      className={`form-control ${touched.resumo && errors.resumo ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.resumo}
                    />
                    {touched.resumo && errors.resumo && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.resumo}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col lg={4}></Col>
              </Row>
              <Row>
                <Col className="pt-3">
                  <Btn type={"secondary"} text="Salvar Alterações" isSumbit={true} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    );
  };



  return (
    <Container className="">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      {controlForm == false ? <div></div> :
        <Row>
          <Col>{renderForm()}</Col>
        </Row>
      }
    </Container>
  );
};


