import { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ptBR } from "date-fns/locale";
import Paragraph from "../../atoms/Paragraph";
import { Col, Container, Row, Table } from "react-bootstrap";
import { format } from "date-fns";
import Icons from "../../atoms/Icons";
import Subtitle from "../../atoms/Subtitle";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";

const id = sessionStorage.getItem("id");

const fusoHorario = "Etc/UTC";
const { parseISO } = require("date-fns");
const { utcToZonedTime } = require("date-fns-tz");

const ExtratoConsultaAdv = () => {
  const [extratoData, setExtratoData] = useState([]);
  const [ordenacaoCrescente, setOrdenacaoCrescente] = useState(false);

  useEffect(() => {
    async function fetchDados() {
      try {
        const api = new FinanceiroService();
        await api
          .extratoConsultaAdvogado()
          .then((data) => {
            if (data.status) {
              setExtratoData(data.data);
            } else {
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }

    fetchDados();
  }, []);

  const exportToCSV = () => {
    const csvData = extratoData.map((item) => ({
      Data: new Date(item.Data).toLocaleDateString("pt-BR"),
      Hora: `${item.Hora.slice(0, 2)}:${item.Hora.slice(2)}`, // Formatar a hora corretamente
      PagamentoConsignado:
        item.PagamentoConsignado[0] === 2 ? "Consignado" : "Avulso",
      Valor: `R$ ${parseFloat(item.Valor).toFixed(2)}`,
      Advogado: item.NomeCliente,
    }));

    const csvContent = [
      ["Data", "Hora", "Tipo de Transação", "Valor", "Cliente"],
      ...csvData.map((item) => [
        item.Data,
        item.Hora,
        item.PagamentoConsignado,
        item.Valor,
        item.NomeCliente,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sortedExtratoData = [...extratoData].sort((a, b) => {
    const dateA = new Date(a.adv_data);
    const dateB = new Date(b.adv_data);

    return ordenacaoCrescente ? dateA - dateB : dateB - dateA;
  });

  const handleToggleOrdenacao = () => {
    setOrdenacaoCrescente(!ordenacaoCrescente);
  };

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );
  };

  const renderTableRows = () => {
    if (sortedExtratoData.length > 0)
      return sortedExtratoData.map((item, index) => (
        <tr key={index} className="align-itens-center table-container">
          <td style={{ width: "20%" }}>
            <Paragraph text={formatDate(item.Data)} />
          </td>
          <td style={{ width: "20%" }}>
            <Paragraph
              text={`${item.Hora.slice(0, 2)}:${item.Hora.slice(2)}`}
            />
          </td>
          <td style={{ width: "20%" }}>
            <Paragraph
              text={item.PagamentoConsignado === 2 ? "Consignado" : "Avulso"}
            />
          </td>
          <td style={{ width: "20%" }}>
            <Paragraph
              text={`R$ ${parseFloat(item.Valor).toLocaleString("pt-BR")},00`}
            />
          </td>
          <td style={{ width: "20%" }}>
            <Paragraph text={item.NomeCliente} />
          </td>
        </tr>
      ));
  };

  return (
    <Container fluid>
      <Row className="d-flex align-items-center justify-content-center extrato-container">
        <Col>
          <Subtitle text="Extrato Consultas" />
        </Col>
        <Col className="d-flex text-end align-items-center">
          <Col lg={10} className="cursor" onClick={exportToCSV}>
            <Paragraph text="EXPORTAR DADOS" />
          </Col>
          <Col lg={2} className="cursor" onClick={exportToCSV}>
            <Icons type="exportar" />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="table-responsive">
          <table className="table table-hover ">
            <thead className="">
              <tr class="table-head-bg">
                <td class="table-head-bg table-rounded-start text-white">Data</td>
                <td class="table-head-bg text-white">Horário</td>
                <td class="table-head-bg text-white">Pagamento</td>
                <td class="table-head-bg text-white">Valor</td>
                <td class="table-head-bg table-rounded-end text-white">Cliente</td>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default ExtratoConsultaAdv;

{
  /* <Table responsive hover  variant="dark" className="tabela-personalizada">
<thead style={{ backgroundColor: 'red', color: 'white' }}>
<tr>
<th>Data</th>
<th>Horário</th>
<th>Pagamento</th>
<th>Valor</th>
<th>Cliente</th>
</tr>
</thead>
<tbody>{renderTableRows()}</tbody>
</Table> */
}
