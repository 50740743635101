import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { HomeService } from "../../../services/HomeServices/HomeService";
import functions from "../../utils/functions";
import { CloseOutlined } from "@ant-design/icons";

const DropdownBtn = ({
  text,
  options,
  onPress,
  defaultValue,
  onClearFilter,
  selected,
}) => {
  const [dados, setDados] = useState([]);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedItem(selected || defaultValue);
  }, [selected, defaultValue]);

  useEffect(() => {
    const API = new HomeService();
    API.getAll().then((data) => {
      if (data.status === true) {
        setDados(data);
      }
    });
  }, []);

  const handleItemClick = (option) => {
    setSelectedItem(option);
    onPress(option);
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleClearFilter = useCallback(() => {
    setFilterText("");
    setSelectedItem(null);
    setIsOpen(false);
    if (onClearFilter) {
      onClearFilter();
    }
  }, [options, onClearFilter]);

  const filteredOptions = options.filter((option) => {
    const descricaoCompleta = functions
      .adicionarDescricaoEstado(option.desc)
      .replace(/-/g, "")
      .toLowerCase();
    const filtro = filterText.replace(/-/g, "").toLowerCase();
    return descricaoCompleta.includes(filtro);
  });

  return (
    <div className="dropdown">
      <button
        className="drop-primario dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedItem
          ? functions.adicionarDescricaoEstado(selectedItem.desc)
          : text}
      </button>

      <ul
        className={`dropdown-menu custom-dropdown-menu ${isOpen ? "show" : ""}`}
      >
        <li className="align-search-input">
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar..."
            value={filterText}
            onChange={handleFilterChange}
          />
          <CloseOutlined
            onClick={handleClearFilter}
            className="align-search-input anticon-close"
          />
        </li>
        {filteredOptions.map((option, index) => (
          <li
            className="item-combo"
            key={index}
            onClick={() => handleItemClick(option)}
          >
            {functions.adicionarDescricaoEstado(option.desc)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownBtn;
