import { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import Icons from "../../atoms/Icons";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";

const id = sessionStorage.getItem("id");

const ExtratoFinanceiroAdv = () => {
  const [extratoData, setExtratoData] = useState([]);

  useEffect(() => {
    async function fetchDados() {
             try {
          const api = new FinanceiroService();
          await api.extratoFinanceiroAdvogado().then((data) => {
        setExtratoData(data.data);
        if(data.status) {
          setExtratoData(data.data);
        } else {
         return false;
        }
      }).catch((error) => {
        console.log(error);
      });

    } catch (error) {
      console.error(error);
    }
  }

    fetchDados();
  }, []);

  

  const exportToCSV = () => {
    const csvData = extratoData.map((item) => ({
      Data: format(new Date(item.data), "dd/MM/yyyy"),
      Valor: "R$ " + item.valor,
      Status: "Pago",
    }));

    const csvContent = [
      ["Data", "Valor", "Status"], 
      ...csvData.map((item) => [
        item.Data,
        item.Valor,
        item.Status,
      ]),
    ]
      .map((row) => row.join(",")) 
      .join("\n"); 

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log("valor de extrato data ->>>", extratoData)

  const renderTableRows = () => {
    if (extratoData.length > 0)
      return extratoData.map((item, index) => (
        <tr key={index} className="align-itens-center table-container">
          <td style={{ width: "80%" }}>
            <Paragraph 
              text={format(new Date(item.tra_data), "dd/MM/yyyy")} />
          </td>
          <td style={{ width: "33,33%" }}>
            <Paragraph
              text={"R$ " + item.tra_valor}
            />
          </td>
          <td style={{ width: "33,33%" }}>
            <Paragraph
              text="Pago"
            />
          </td>
        </tr>
      ));
  };
 
 
  return (
    <Container>
      <Row className="d-flex align-items-center justify-content-center extrato-container">
        <Col>
          <Subtitle text="Extrato pagamentos" />
        </Col>
        <Col className="d-flex text-end align-items-center">
          <Col lg={10} className="cursor" onClick={exportToCSV}>
            <Paragraph text="EXPORTAR DADOS" />
          </Col>
          <Col lg={2} className="cursor" onClick={exportToCSV}>
            <Icons type="exportar" />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="table-responsive">
          <table className="table table-hover ">
            <thead className="">
              <tr class="table-head-bg">
                <td style={{ width: "33,33%" }} class="table-head-bg table-rounded-start text-white">Data</td>
                <td class="table-head-bg text-white">Valor</td>
                <td class="table-head-bg table-rounded-end text-white">Status</td>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default ExtratoFinanceiroAdv;