import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";
import { Rating } from "react-simple-star-rating";
import Paragraph from "../../atoms/Paragraph";
import { useNavigate } from "react-router-dom";
import { FotoPerfilCadastroModal } from "../FotoPerfilCadastroModal";

export default function CabecalhoPerfilAdv({ data }) {
  const [defaultAdvogadoEstrelas, setDefaultAdvogadoEstrelas] = useState([]);
  const api = new PerfilService();
  const navigate = useNavigate();
  const nomeUsuario = sessionStorage.getItem("nome") || "";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (novaFoto) => {
    try {
      await api.adicionarFoto(novaFoto)
      .then((data) => {
        if(data.status == true) {
          sessionStorage.setItem('fotoPerfil',novaFoto)
        }
      })
      ;
      setShow(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (data != null) {
      setDefaultAdvogadoEstrelas(data.data.dadosAdvogado.estrelas);
    }
  }, [data]);

  const handleAvaliacoes = () => {
    navigate("/AvaliacoesAdvTemp");
  };

  return (
    <Container className="mb-5">
      <Row className="align-items-center">
        <Col lg={2} className="avatar-container">
          <AvatarPerfil
            type="A"
            base64={sessionStorage.getItem("fotoPerfil")}
            width="13rem"
            height="13rem"
            padding="0.4rem"
          />
        </Col>

        <Col lg={8} className="py-2 mt-2 ms-5 ">
          <Subtitle text={"Olá, " + nomeUsuario} />
          <Col lg={4} className="d-flex align-items-center gap-1">
            <Col lg={2} className="mt-3">
              <Subtitle text={defaultAdvogadoEstrelas} />
            </Col>
            <Col>
              <Rating readonly={true} initialValue={defaultAdvogadoEstrelas} />
            </Col>
            <Col lg={6} className="ms-2 mt-3 cursor" onClick={handleAvaliacoes}>
              <Paragraph text="Ver avaliações" isSubmit={true} />
            </Col>
          </Col>

          <Btn className={"gap-1"}
            type={"secondary-sm"}
            isSubmit={false}
            text="Trocar foto"
            onTouchable={handleShow}
          />

          <FotoPerfilCadastroModal
            show={show}
            handleClose={handleClose}
            setShow={setShow}
            handleFotoPerfil={handleSubmit}
          />
        </Col>
      </Row>
    </Container>
  );
}
