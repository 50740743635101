import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes, useLocation, redirect, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home} from "../pages/Home";
import { Login } from "../pages/Login";
import { Cadastro } from "../pages/Cadastro";
import { NotFound } from "../pages/NotFound";
import { Forum } from "../components/templates/ForumTemp";
import { FaleConosco } from "../components/templates/FaleConosco";
import { LandingPageAdvTemp } from "../components/templates/LandingPageAdvTemp";
import { PerfilClienteTemp } from "../components/templates/PerfilClienteTemp";
import { ForumCli } from "../components/templates/ForumCliTemp";
import { ForumAdv } from "../components/templates/ForumAdvTemp ";
import { PerfilAdvTemp } from "../components/templates/PerfilAdvTemp";
import { ConsultasAdv } from "../components/templates/ConsultasAdv";
import { FincanceiroCli } from "../components/templates/FinanceiroCliTemp";
import { AgendamentoConsulta } from "../pages/AgendamentoConsulta";
import {
  FinanceiroAdv,
  FincanceiroAdv,
} from "../components/templates/FinanceiroAdvTemp";
import { ResultadoSearch } from "../components/templates/ResultadoSearch";
import { ConsultasCli } from "../components/templates/ConsultasCli";
import { VideoConferencia } from "../pages/VideoConferencia";
import { RedefinirSenha } from "../pages/RedefinirSenha";
import { TermosDeUso } from "../components/templates/TermosDeUso";
import { PoliticaDePrivacidade } from "../components/templates/PoliticaDePrivacidade";
import { PerguntasFrequentes } from "../components/templates/PerguntasFrequentes";
import { CarrosselLpOne } from "../components/templates/CarrosselLpOne";
import { CarrosselLpTwo } from "../components/templates/CarrosselLpTwo";
import { DashboardTemp } from "../components/templates/Administrativo/DashboardTemp";
import { GerencialUsuariosTemp } from "../components/templates/Administrativo/GerencialUsuariosTemp";
import { GerencialUsuariosCadastrarTemp } from "../components/templates/Administrativo/GerencialUsuariosCadastrarTemp";
import { GerencialUsuariosEditarTemp } from "../components/templates/Administrativo/GerencialUsuariosEditarTemp";
import { SiteAdvogadosTemp } from "../components/templates/Administrativo/SiteAdvogadosTemp";
import { SiteClientesTemp } from "../components/templates/Administrativo/SiteClientesTemp";
import { usePermissaoAdm } from "../hooks/usePermissaoAdm";
import { GerencialPerfisTemp } from "../components/templates/Administrativo/GerencialPerfisTemp";
import { GerencialPerfisCadastrarTemp } from "../components/templates/Administrativo/GerencialPerfisCadastrarTemp";
import { FinanceiroConsignadoTemp } from "../components/templates/Administrativo/FinanceiroConsignadoTemp";
import { FinanceiroParticularTemp } from "../components/templates/Administrativo/FinanceiroParticularTemp";
import { LoginTemp } from "../components/templates/Administrativo/LoginTemp";
import { SiteAdvogadosValidarTemp } from "../components/templates/Administrativo/SiteAdvogadosValidarTemp";
import { SiteClienteEditarTemp } from "../components/templates/Administrativo/SiteClienteEditarTemp";
import { NotificacoesAdvogados } from "../components/templates/NotificacoesAdvogados";
import { PerfilService } from "../services/AdmUsuarioServices/PerfilService";
import { GerencialPerfisEditarTemp } from "../components/templates/Administrativo/GerencialPerfisEditarTemp";
import { Permissao } from "../pages/Permissao";
import { Validacao } from "../pages/Validacao";
import { FotoEmailTemp } from "../components/templates/FotoEmailTemp";
import { AvaliacoesAdvTemp } from "../components/templates/AvaliacoesAdvTemp";
import { NotificacoesCli } from "../components/templates/NotificacoesCli";
import Meeting from "../pages/Meeting";

const AppRouter = () => {
  const [perm, setPerm] = useState([]);
  const pathname = window.location.pathname;

  const api = new PerfilService();

  async function fetchUserData() {
    try {
      const response = await api.getPermissoes(); //passar id do session storage
      if (response.status) {
        setPerm(response.data);
      }
    } catch (error) {
      console.log("Erro ao buscar dados da API:", error);
    }
  }
  useEffect(() => {
    if (pathname.includes("adm")) {
      if (pathname === "/adm/login") {
        return;
      }
      fetchUserData();
    }
  }, []);

  function isAdvogado() {
    if(!sessionStorage.getItem('token') || sessionStorage.getItem('tipo') !== 'A') {
      throw redirect('/Login/Adv')
    }
    return true;
  }

  function isAdvogadoVerificado() {
    if(!sessionStorage.getItem('token') || sessionStorage.getItem('tipo') !== 'A') {
      throw redirect('/Login/Adv')
    }
    const etapa_1 = sessionStorage.getItem("etapa_1");
    const etapa_2 = sessionStorage.getItem("etapa_2");
    const etapa_3 = sessionStorage.getItem("etapa_3");
    const etapa_4 = sessionStorage.getItem("etapa_4");
    const etapa_5 = sessionStorage.getItem("etapa_5");  

    if (
      etapa_1 !== "true" ||
      etapa_2 !== "true" ||
      etapa_3 !== "true" ||
      etapa_4 !== "true" ||
      etapa_5 !== "true"
    ) {
      
      throw redirect('/');
    }
    return true;
  }

  async function isCliente() {
    if(!sessionStorage.getItem('token') || sessionStorage.getItem('tipo') !== 'C') {
      throw redirect('/Login/Cli')
    }
    return true;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/ValidarCadastro/:chave",
      element:<Validacao />
    },
    {
      path: "/Login/:typeUser",
      element:<Login />
    },
    {
      path: "/Cadastro/:typeUser",
      element:<Cadastro />
    },
    {
      path: "/TermosDeUso",
      element:<TermosDeUso />
    },
    {
      path: "/FotoEmail",
      element:<FotoEmailTemp />
    },
    {
      path: "/meet/:typeUser/:consultaId",
      element:<Meeting />
    },
    {
      path: "/PoliticaDePrivacidade",
      element:<PoliticaDePrivacidade />
    },
    {
      path: "/PerguntasFrequentes",
      element:<PerguntasFrequentes />
    },
    {
      path: "/CarrosselLpOne",
      element:<CarrosselLpOne />
    },
    {
      path: "/CarrosselLpTwo",
      element:<CarrosselLpTwo />
    },
    {
      path: "/LandingPageAdv",
      element:<LandingPageAdvTemp />
    },
    {
      path: "/PerfilClienteTemp",
      loader: isCliente,
      element:<PerfilClienteTemp />
    },
    {
      path: "/ResultadoSearch",
      element:<ResultadoSearch />
    },
    {
      path: "/ResultadoSearch/:id",
      element:<ResultadoSearch />
    },
    {
      path: "/ConsultasCli",
      loader: isCliente,
      element:<ConsultasCli />
    },
    {
      path: "/Forum",
      element:<Forum />
    },
    {
      path: "/ForumCli",
      loader: isCliente,
      element:<ForumCli />
    },
    {
      path: "/ForumAdv",
      loader: isAdvogadoVerificado,
      element:<ForumAdv />
    },
    {
      path: "/NotificacoesCli",
      loader: isCliente,
      element:<NotificacoesCli />
    },
    {
      path: "/NotificacoesAdvogados",
      loader: isAdvogado,
      element:<NotificacoesAdvogados />
    },
    {
      path: "/VideoConferencia/:typeUser",
      element:<VideoConferencia />
    },
    {
      path: "/FinanceiroCli",
      loader: isCliente,
      element:<FincanceiroCli />
    },
    {
      path: "/FaleConosco",
      element:<FaleConosco />
    },
    {
      path: "/PerfilAdvTemp",
      loader: isAdvogadoVerificado,
      element:<PerfilAdvTemp />
    },
    {
      path: "/AvaliacoesAdvTemp",
      loader: isAdvogadoVerificado,
      element:<AvaliacoesAdvTemp />
    },
    {
      path: "FinanceiroAdv",
      loader: isAdvogadoVerificado,
      element:<FinanceiroAdv />
    },
    {
      path: "/ConsultasAdv",
      loader: isAdvogadoVerificado,
      element:<ConsultasAdv />
    },
    {
      path: "/Agendamento/:advId", 
      element:<AgendamentoConsulta />
    },
    {
      path: "/RedefinirSenha/:token", 
      element:<RedefinirSenha />
    },
    {
      path: "/adm",
      element: <LoginTemp />
    }, 
    {
      path: "/adm/login",
      element:<LoginTemp />
    }, 
    {
      path: "/adm/dashboard",
      element: usePermissaoAdm("dashboard", perm) ? (
        <DashboardTemp />              
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/financeiro/consignado",
      element: usePermissaoAdm("financeiro-consignado-acessar", perm) ? (
        <FinanceiroConsignadoTemp/>
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/financeiro/particular",
      element: usePermissaoAdm("financeiro-particular-acessar", perm) ? (
        <FinanceiroParticularTemp/>
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/gerencial/usuarios",
      element: usePermissaoAdm("gerencial-usuarios-acessar", perm) ? (
        <GerencialUsuariosTemp permissoes={perm} />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/gerencial/usuarios/cadastrar",
      element: usePermissaoAdm("gerencial-usuarios-cadastrar", perm) ? (
        <GerencialUsuariosCadastrarTemp />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/gerencial/usuarios/editar/:id",
      element: <GerencialUsuariosEditarTemp />
    },
    {
      path: "/adm/gerencial/perfil",
      element: usePermissaoAdm("gerencial-perfis-acessar", perm) ? (
        <GerencialPerfisTemp permissoes={perm} />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/gerencial/perfil/cadastrar",
      element: usePermissaoAdm("gerencial-perfis-cadastrar", perm) ? (
        <GerencialPerfisCadastrarTemp />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/gerencial/perfil/editar/:id",
      element: usePermissaoAdm("gerencial-perfis-cadastrar", perm) ? (
        <GerencialPerfisEditarTemp />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/site/clientes",
      element: usePermissaoAdm("plataforma-clientes-acessar", perm) ? (
        <SiteClientesTemp />
      ) : (
        <Permissao />
      )
    }, 
    {
      path: "/adm/site/clientes/editar/:id",
      element: usePermissaoAdm("plataforma-cliente-editar", perm) ? (
        <SiteClienteEditarTemp />
      ) : (
        <Permissao />
      )
    }, 
    {
      path: "/adm/site/advogados",
      element: usePermissaoAdm("plataforma-advogado-acessar", perm) ? (
        <SiteAdvogadosTemp />
      ) : (
        <Permissao />
      )
    },
    {
      path: "/adm/site/advogados/validar/:id",
      element: usePermissaoAdm("plataforma-advogado-editar", perm) ? (
        <SiteAdvogadosValidarTemp />
      ) : (
        <Permissao />
      )
    },
    {
      path: "*",
      element: <NotFound />
    }
    
  ], {  });

  return (
    // <ErrorBoundary>
      <RouterProvider router={router} />
    // </ErrorBoundary> 
  );

  // return (
  //   <BrowserRouter>
  //     <Routes>
  //       /*----------SITE----------*/
  //       <Route path="/ValidarCadastro/:chave" element={<Validacao />} />
  //       <Route path="/" element={<Home />} /> 
  //       <Route path="/Login/:typeUser" element={<Login />} />
  //       <Route path="/Cadastro/:typeUser" element={<Cadastro />} />
  //       <Route path="/TermosDeUso" element={<TermosDeUso />} />
  //       <Route path="/FotoEmail" element={< FotoEmailTemp />} />

  //       <Route path="/meet/:typeUser/:consultaId" element={<Meeting />} />

       
  //       <Route
  //         path="/PoliticaDePrivacidade"
  //         element={<PoliticaDePrivacidade />}
  //       />
  //       <Route path="/PerguntasFrequentes" element={<PerguntasFrequentes />} />
  //       <Route path="/CarrosselLpOne" element={<CarrosselLpOne />} />
  //       <Route path="/CarrosselLpTwo" element={<CarrosselLpTwo />} />
  //       <Route path="LandingPageAdv" element={<LandingPageAdvTemp />} />
  //       <Route path="PerfilClienteTemp" element={<PerfilClienteTemp />} />
        
  //       <Route path="/ResultadoSearch" element={<ResultadoSearch />} />
  //       <Route path="/ResultadoSearch/:id" element={<ResultadoSearch />} />

  //       <Route path="ConsultasCli" element={<ConsultasCli />} />
  //       <Route path="/Forum" element={<Forum />} />
  //       <Route path="/ForumCli" element={<ForumCli />} />
  //       <Route path="/ForumAdv" element={<ForumAdv />} />
  //       <Route path="/NotificacoesCli" element={<NotificacoesCli />} />
  //       <Route path="/NotificacoesAdvogados" element={<NotificacoesAdvogados />} />
  //       <Route
  //         path="/VideoConferencia/:typeUser"
  //         element={<VideoConferencia />}
  //       />
  //       <Route path="/FinanceiroCli" element={<FincanceiroCli />} />
  //       <Route path="/FaleConosco" element={<FaleConosco />} />
  //       <Route path="PerfilAdvTemp" element={<PerfilAdvTemp />} />
  //       <Route path="/AvaliacoesAdvTemp" element={<AvaliacoesAdvTemp />} />
  //       <Route path="/FinanceiroAdv" element={<FinanceiroAdv />} />
  //       <Route path="ConsultasAdv" element={<ConsultasAdv />} />
      
      
      
  //       {/* <Route path='Agendamento/:advId' element={<AgendamentoConsulta />} /> */}
  //       <Route path="/Agendamento/:advId" element={<AgendamentoConsulta />} />
  //       <Route path="/RedefinirSenha/:token" element={<RedefinirSenha />} />
  //       /*------------ADM------------*/
  //       <Route path="/adm" element={<LoginTemp />} />
  //       <Route path="/adm/login" element={<LoginTemp />} />
  //       {/* <Route path="/adm/dashboard" element={<DashboardTemp />} /> */}
  //       <Route
  //         path="/adm/dashboard"
  //         element={
  //           usePermissaoAdm("dashboard", perm) ? (
  //             <DashboardTemp />              
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/financeiro/consignado"
  //         element={
  //           usePermissaoAdm("financeiro-consignado-acessar", perm) ? (
  //             <FinanceiroConsignadoTemp/>
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/financeiro/particular"
  //         element={
  //           usePermissaoAdm("financeiro-particular-acessar", perm) ? (
  //             <FinanceiroParticularTemp/>
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/gerencial/usuarios"
  //         element={
  //           usePermissaoAdm("gerencial-usuarios-acessar", perm) ? (
  //             <GerencialUsuariosTemp permissoes={perm} />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/gerencial/usuarios/cadastrar"
  //         element={
  //           usePermissaoAdm("gerencial-usuarios-cadastrar", perm) ? (
  //             <GerencialUsuariosCadastrarTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/gerencial/usuarios/editar/:id"
  //         element={<GerencialUsuariosEditarTemp />}
  //       />
  //       <Route
  //         path="/adm/gerencial/perfil"
  //         element={
  //           usePermissaoAdm("gerencial-perfis-acessar", perm) ? (
  //             <GerencialPerfisTemp permissoes={perm} />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/gerencial/perfil/cadastrar"
  //         element={
  //           usePermissaoAdm("gerencial-perfis-cadastrar", perm) ? (
  //             <GerencialPerfisCadastrarTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/gerencial/perfil/editar/:id"
  //         element={
  //           usePermissaoAdm("gerencial-perfis-cadastrar", perm) ? (
  //             <GerencialPerfisEditarTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/site/clientes"
  //         element={
  //           usePermissaoAdm("plataforma-clientes-acessar", perm) ? (
  //             <SiteClientesTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
        
  //       <Route
  //         path="/adm/site/clientes/editar/:id"
  //         element={
  //           usePermissaoAdm("plataforma-cliente-editar", perm) ? (
  //             <SiteClienteEditarTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />

  //       <Route
  //         path="/adm/site/advogados"
  //         element={
  //           usePermissaoAdm("plataforma-advogado-acessar", perm) ? (
  //             <SiteAdvogadosTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       <Route
  //         path="/adm/site/advogados/validar/:id"
  //         element={
  //           usePermissaoAdm("plataforma-advogado-editar", perm) ? (
  //             <SiteAdvogadosValidarTemp />
  //           ) : (
  //             <Permissao />
  //           )
  //         }
  //       />
  //       /*---------------------------*/
  //       <Route path="*" element={<NotFound />} />
  //     </Routes>
  //   </BrowserRouter>
  // );
};

export default AppRouter;
