import React, { useState } from "react";
import "./styles.css";
import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { Toasts } from "../../utils/Toasts";
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { Formik } from "formik";
import Subtitle from "../../atoms/Subtitle";
import { NotificacaoService } from "../../../services/HomeServices/NotificacaoService";

export const LembreteAdvModal = ({ show, onHide }) => {

  const api = new NotificacaoService();
  const id = sessionStorage.getItem("id");

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const fecharToast = () => {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  };

  const schema = yup.object().shape({
    titulo: yup.string().required("O título da notificação é obrigatório."),
    horario: yup.string().required("O horário do compromisso é obrigatório."),
    dia: yup.string().required("O dia do compromisso é obrigatório."),
    descricao: yup.string().required("A descrição do lembrete é obrigatória."),
  });


  const handleEnviarLembrete = async (values) => {
    try {
      const response = await api.lembreteAdvogado(
        values.titulo,
        values.horario,
        values.dia,
        values.descricao
      );
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      let optionsToast = {
        code: "102",
        message: "Não foi possível salvar seu lembrete, tente novamente.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="medium">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="d-flex flex-column">
            <Col>
              <Subtitle text="Notificação personalizada"></Subtitle>
            </Col>
            <Col className="mt-3">
              <Formik
                validationSchema={schema}
                onSubmit={(values) =>
                  handleEnviarLembrete(values)
                }
                initialValues={{
                  titulo: "",
                  horario: "",
                  dia: "",
                  descricao: "",
                }}
              >
                {({ handleChange, handleSubmit, touched, errors, values }) => (
                  <Form className="" onSubmit={handleSubmit}>
                    <Form.Group>
                      <Col>
                        <Form.Control
                          className={`margim-campos-formulario ${touched.titulo && errors.titulo ? "is-invalid" : ""}`}
                          placeholder="Descreva o título da notificação."
                          type="text"
                          name="titulo"
                          maxLength={50}
                          value={values.titulo}
                          onChange={handleChange}
                          isValid={touched.titulo && !errors.titulo}
                        />
                      </Col>
                      <Col className="d-flex gap-3">
                        <InputMask
                          mask="99:99"
                          value={values.horario}
                          onChange={handleChange}>
                          {(inputProps) => (
                            <Form.Control
                              className={`margim-campos-formulario ${touched.horario && errors.horario ? "is-invalid" : ""}`}
                              type="text"
                              name="horario"
                              value={values.horario}
                              onChange={handleChange}
                              isValid={touched.horario && !errors.horario}
                              placeholder="Horário"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                        <InputMask
                          mask="99-99-9999"
                          value={values.dia}
                          onChange={handleChange}>
                          {(inputProps) => (
                            <Form.Control
                              className={`margim-campos-formulario ${touched.dia && errors.dia ? "is-invalid" : ""}`}
                              type="text"
                              name="dia"
                              value={values.dia}
                              onChange={handleChange}
                              isValid={touched.dia && !errors.dia}
                              placeholder="Data"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </Col>

                      <Col>
                        <Form.Control
                          className={`margim-campos-formulario ${touched.descricao && errors.descricao ? "is-invalid" : ""}`}
                          placeholder="Descreva o lembrete para que o notifiquemos."
                          type="text"
                          as="textarea"
                          style={{ height: "7rem" }}
                          name="descricao"
                          maxLength={150}
                          value={values.descricao}
                          onChange={handleChange}
                          isValid={touched.descricao && !errors.descricao}
                        />
                      </Col>
                      {/* {touched.descricao && errors.descricao && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.descricao}
                        </Form.Control.Feedback>
                      )}
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.descricao}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                    <Row>
                      <Col className="d-flex justify-content-between">
                        <Btn type="terciary-sm" text="Fechar" onTouchable={onHide} />
                        <Btn
                          type="secondary-sm"
                          text="Enviar"
                          isSumbit={true}
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

        </Container>
      </Modal.Body>
    </Modal>
  );
};