import React, { useState, useEffect, useMemo, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { Notificacao } from "../../molecules/Notificacao";
import Btn from "../../atoms/Button";
import { Form } from "react-bootstrap";
import { ptBR } from "date-fns/locale";
import { set } from "date-fns";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import functions from "../../utils/functions";
import { NotificacaoService } from "../../../services/HomeServices/NotificacaoService";
import Paragraph from "../../atoms/Paragraph";
import { Toasts } from "../../utils/Toasts";

export const NotificacaoCli = () => {
  const api = new NotificacaoService();
  const id = sessionStorage.getItem("id");

  const [notificacoesArray, setNotificacoesArray] = useState([]);
  const [mensagemLidas, setMensagemLidas] = useState([]);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };
  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return "";
    }
    const zonedDate = utcToZonedTime(parseISO(dateString), fusoHorario);
    const formataData = format(zonedDate, "dd/MM/yyyy - HH:mm", {
      locale: ptBR,
    });

    return formataData;
  };

  const [checkedValor, setCheckedValor] = useState(null); 

  const handleAmarzenaId = (notificacaoId, isChecked) => {
    if (isChecked === true) {
      setMensagemLidas((prevArray) => [...prevArray, notificacaoId]);
    } else {
      setMensagemLidas((prevArray) =>
        prevArray.filter((id) => id !== notificacaoId)
      );
    }
  };
  
  const handleAlteraTodos = (isChecked) => {
    if(isChecked == true){
      setCheckedValor(isChecked)
      notificacoesArray.map((notificacao, index) => (
        handleAmarzenaId(notificacao.notId, true)
      ))

    } else {
        setCheckedValor(null)
        notificacoesArray.map((notificacao, index) => (
          handleAmarzenaId(notificacao.notId, false)
        ))
    }
  }
  
  const handleDesabilita = () => {
    if (checkedValor == true) {
      setCheckedValor(null);
      notificacoesArray.map((notificacao, index) => (
        handleAmarzenaId(notificacao.notId, false)
      ));
    }
  }

  const handleMensagemLida = async () => {
    try {
      const data = await api.mensagemLidaCli(mensagemLidas);

      let optionsToast = {
        code: data.mensagem.codigo,
        message: data.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
      
      setTimeout(() => {
        window.location.reload();
      }, 2200);
    } catch (e) {
      console.log(e);
      let optionsToast = {
        code: "102",
        message: "Selecione as notificações que deseja ocultar.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
  };

  const fetchListarNotificacoes = async () => {
    try {
      const response = await api.listarNotificacoesCli();
      console.log("---->", response);
      if (response.status && Array.isArray(response.data)) {
        const notificacoes = response.data.map((item) => ({
          notMensagem: item.not_mensagem,
          notTpoId: item.not_tpo_id,
          notData: item.not_data,
          notLido: item.not_lido,
          notAddExibir: item.not_add_exibir,
          notTitulo: item.not_titulo,
          notId: item.not_id,
        }));

        setNotificacoesArray(notificacoes);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchListarNotificacoes();
  }, [id]);

  return (
    <Container className="">  

    <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />

      <Row className="justify-content-end pe-2  mt-4">        
        <Col
          lg={3}
          className="d-flex justify-content-end align-items-center gap-3 pe-4"
        >
          <ParagraphSmall text="Selecionar todas" />
          <Form.Switch
            type="switch"
            id="custom-switch"
            checked={checkedValor}
            onChange={(e) => handleAlteraTodos(e.target.checked)}
          />
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center">
        <Col style={{ maxHeight: "500px", overflowY: "auto" }}>
          {notificacoesArray.length > 0 ? (
            notificacoesArray.map((notificacao, index) => (
              <div key={index} className="d-flex align-items-center gap-2">
                <Notificacao
                  text={notificacao.notTitulo}
                  text3={notificacao.notMensagem}
                  text2={formatDateTime(notificacao.notData)}
                />
                <Form.Check
                  type="switch"
                  checked={checkedValor}
                  onClick={handleDesabilita}
                  onChange={(e) =>
                    handleAmarzenaId(notificacao.notId, e.target.checked)
                  }
                  id={`custom-switch-${index}`}
                />
              </div>
            ))
          ) : (
            <Col className="bg-form p-2 rounded shadow">
              <Paragraph text="Não existem notificações novas." />
            </Col>
          )}
        </Col>

        <Col className="d-flex mt-2 justify-content-end pe-4">
          <Btn
            type={"secondary-sm"}
            isSubmit={false}
            onTouchable={handleMensagemLida}
            text="Marcar como lida"
          />
        </Col>
      </Row>
    </Container>
  );
};
