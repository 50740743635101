import functions from "../../components/utils/functions";
import api from "../Api";

export class ConsultasService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async buscarConsultasAgendadasCli() {
    try {
      const response = await api.post(
        "/cliente/buscar-consultas-cli",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (response.data.status && response.data.data) {
        return response.data.data;
      } else {
        console.error("API returned an error:", response.data.mensagem);
        throw new Error("API Error");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async historicoConsultasCli() {
    try {
      const response = await api.post(
        "/cliente/historico-consultas-cli",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (response.data.status && response.data.data) {
        return response.data.data;
      } else {
        console.error("API returned an error:", response.data.mensagem);
        throw new Error("API Error");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async listarDadosReagendamento(idAdv) {
    try {
      const response = await api.post(
        "/cliente/listar-dados-reagendamento",
        {
          cli_id: idAdv,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (response.data.status && response.data.data) {
        return response.data.data;
      } else {
        console.error("API returned an error:", response.data.mensagem);
        throw new Error("API Error");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async listarHorarioReagendamento(id, data) {
    try {
      const response = await api.post(
        "/cliente/listar-horario-reagendamento",
        {
          id: id,
          data: data,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (response.data.status && response.data.data) {
        return response.data.data;
      } else {
        console.error("API returned an error:", response.data.mensagem);
        throw new Error("API Error");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async reagendarConsulta(novaConsultaId, consultaCancelarId, motivo) {
    return await api
      .post(
        "/cliente/reagendar-consulta-cli",
        {
          novaConsultaId: novaConsultaId,
          consultaCancelarId: consultaCancelarId,
          motivo: motivo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async consultaViaPlano(idConsulta, idAdvogado) {
    return await api
      .post(
        "/cliente/consulta-via-plano",
        {
          adv_id: idConsulta,
          add_id: idAdvogado,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async cancelarConsultaCli(idConsulta) {
    return await api
      .post(
        "/cliente/cancelar-consulta-cli",
        {
          adv_id: idConsulta,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }
}
