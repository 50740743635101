import functions from "../../components/utils/functions";
import api from "../Api";

export class ConsultaService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postLogin(cpf, senha, recaptcha_token) {
    return await api
      .post(
        "/advogado/login-advogado",
        {
          cpf: cpf,
          senha: senha,
          tokenRecaptcha: recaptcha_token,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postSalvarAgenda(
    daysAndHours,
    replicatePeriod
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/salvar-agenda",
        {
          daysAndHours: daysAndHours,
          replicatedTo: replicatePeriod,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async getConsultas(
    data
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/buscar-consultas",
        {
          data: data,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async getAgenda(
    data
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/buscar-agenda",
        {
          data: data,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirHorarioAgenda(
    idAdv
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/cancelar-horario-agenda",
        {
          idAdv: idAdv,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirAgenda(

    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/cancelar-agenda",
        {
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async historicoConsultas() {
    try {
      const response = await api.post(
        "/advogado/historico-consultas",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } else {
        throw error; 
      }
    }
  }

  async postCancelarConsulta(
    id,
    idDaConsulta
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/cancelar-consulta",
        {
          id: id,
          idDaConsulta: idDaConsulta,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async postReagendarConsulta(
    id,
    idDaConsulta,
    daysAndHours
    //recaptcha_token
  ) {
    return await api
      .post(
        "/advogado/reagendar-consulta-cli",
        {
          id: id,
          idDaConsulta: idDaConsulta,
          daysAndHours: daysAndHours,
          // "tokenRecaptcha" : recaptcha_token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async notasAdvogadoHistorico(id, cli_id, comentario, arquivoAnexado) {
    let formData = new FormData();
    formData.append("cli_id_advogado", id);
    formData.append("cli_id", cli_id);
    formData.append("comentario", comentario);
    formData.append("arquivoAnexado", arquivoAnexado);

    return await api
      .post("/advogado/notas-historico-adv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async listarNotasAdvogadoHistorico(cli_id) {
    try {
      const response = await api.post(
        "/advogado/lista-notas-adv",
        {
          cli_id: cli_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async arquivoAnexadoAdv(doc) {
    try {
      const response = await api.post(
        "/advogado/arquivos-adv-anotacao",
        {
          doc: doc,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async listarConsultas() {
    return await api
      .post(
        "/advogado/listar-consultas",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async cancelarConsultaAdv(id, idAgenda) {
    return await api
      .post(
        "/advogado/cancelar-consulta-adv",
        {
          cli_id: id,
          adv_id: idAgenda
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }
}
