import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import produce from 'immer';
import ZoomVideo, { ConnectionState, ReconnectReason, Stream, VideoQuality } from '@zoom/videosdk';
import uitoolkit from '@zoom/videosdk-ui-toolkit';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import LoadingLayer from '../../components/organisms/LoadingLayer';
import { Button } from 'react-bootstrap';

import { PhoneFilled, VideoCameraFilled } from '@ant-design/icons';

import { Icon } from 'react-icons-kit'

import {ic_mic} from 'react-icons-kit/md/ic_mic'
import {ic_mic_off} from 'react-icons-kit/md/ic_mic_off'

import {ic_videocam} from 'react-icons-kit/md/ic_videocam';
import {ic_videocam_off} from 'react-icons-kit/md/ic_videocam_off';

import {logout} from 'react-icons-kit/iconic/logout'
import {ic_sensor_door_outline} from 'react-icons-kit/md/ic_sensor_door_outline'

import {ic_close} from 'react-icons-kit/md/ic_close'

import {ic_volume_mute} from 'react-icons-kit/md/ic_volume_mute';
import {ic_volume_down} from 'react-icons-kit/md/ic_volume_down';
import {ic_volume_up} from 'react-icons-kit/md/ic_volume_up';

import {ic_screen_share} from 'react-icons-kit/md/ic_screen_share';
import {ic_stop_screen_share} from 'react-icons-kit/md/ic_stop_screen_share';

import './styles.css'
import { ZoomServices } from '../../services/ZoomServices/ZoomService';
import { useParams } from 'react-router-dom';

const aspectRatio = 16 / 9;

const minCellWidth = 256;
const minCellHeight = minCellWidth / aspectRatio;

const maxCellWidth = 854;
const maxCellHeight = maxCellWidth / aspectRatio;

// const mediaShape = {
//     audio: {
//       encode: false,
//       decode: false
//     },
//     video: {
//       encode: false,
//       decode: false
//     },
//     share: {
//       encode: false,
//       decode: false
//     }
//   };

//   const mediaReducer = produce((draft, action) => {
//     switch (action.type) {
//       case 'audio-encode': {
//         draft.audio.encode = action.payload;
//         break;
//       }
//       case 'audio-decode': {
//         draft.audio.decode = action.payload;
//         break;
//       }
//       case 'video-encode': {
//         draft.video.encode = action.payload;
//         break;
//       }
//       case 'video-decode': {
//         draft.video.decode = action.payload;
//         break;
//       }
//       case 'share-encode': {
//         draft.share.encode = action.payload;
//         break;
//       }
//       case 'share-decode': {
//         draft.share.decode = action.payload;
//         break;
//       }
//       case 'reset-media': {
//         Object.assign(draft, { ...mediaShape });
//         break;
//       }
//       default:
//         break;
//     }
//   }, mediaShape);

// var dadosMeetingAdv = {
//     consultaId: 177,
//     topic: "d526fbb6-e105-47c7-89c2-f5ffcc2686fe",
//     role: 'A',
//     userIdentity: 327,
//     userName: "Advogado",
//     sessionPasscode: 'WDk3JQZP',
//     token: "",
// };

// var dadosMeetingCli = {
//     consultaId: 177,
//     topic: "d526fbb6-e105-47c7-89c2-f5ffcc2686fe",
//     role: 'C',
//     userIdentity: 326,
//     userName: "Cliente",
//     sessionPasscode: 'WDk3JQZP',
//     token: "",
// };

function Meeting() {

    const api = useMemo(() => new ZoomServices(), []);

    const videoRef = useRef(null);
    const otherVideoRef = useRef(null);
    const shareCanvasRef = useRef(null);
    const shareVideoRef = useRef(null);

    const [loading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('');
    const [mediaStream, setMediaStream] = useState(null);

    const [isStartedMeeting, setIsStartedMeeting] = useState(false);

    const [isStartedVideo, setIsStartedVideo] = useState(false);
    const [isStartedAudio, setIsStartedAudio] = useState(false);
    const [isStartedAudioEncode, setIsStartedAudioEncode] = useState(false);
    const [isStartedAudioDecode, setIsStartedAudioDecode] = useState(false);
    const [volume, setVolume] = useState(false);
    const [lastVolume, setLastVolume] = useState(100);

    const [isSharing, setIsSharing] = useState(false);
    const [isVideo, setIsVideo] = useState(false);

    const [isMutedAudio, setIsMutedAudio] = useState(true);

    const [isStartedOtherVideo, setIsStartedOtherVideo] = useState(false);

    const [isRoomCreated, setIsRoomCreated] = useState(false);

    const mediaContext = useMemo(() => ({mediaStream}), [mediaStream]);

    const zmClient = ZoomVideo.createClient();

    const [participants, setParticipants] = useState([]);
    const [visibleParticipants, setVisibleParticipants] = useState([]);
    const [subscribedVideos, setSubscribedVideos] = useState([]);

    const [myUserSession, setMyUserSession] = useState(null);
    const [otherUserSession, setOtherUserSession] = useState(null);

    const cli_id = sessionStorage.getItem("id");
    const userName = sessionStorage.getItem("nome");

    const { typeUser, consultaId } = useParams();

    const [videoDimensions, setVideoDimensions] = useState(getVideoDimensions());
    const [screens, setScreens] = useState([]);

    const [videoActive, setVideoActive] = useState([]);

    function getVideoDimensions() {
        console.log("getVideoDimensions")

        const minWidth = 1024;

        let isResponsible = false;

        let maxWidth = (window.innerWidth / 2) - 20;
        let maxHeight = (window.innerWidth / 1.5) - 20;

        let width = window.innerWidth;
        let height = window.innerHeight;
        
        const aspectRatio = 16 / 9; // Proporção de 1080p

        if (minWidth < width) {
            maxWidth = (window.innerWidth / 2) - 20;
            maxHeight = (window.innerHeight / 1.5) - 20;
            isResponsible = true;
        } else {
            maxWidth = window.innerWidth;
            maxHeight = window.innerHeight;
            isResponsible = true;
        }

        // const maxWidth = 854;
        // const maxHeight = 480;

        if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
        } else {
            width = width - 20;
            height = width / aspectRatio;
        }

        if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
        } else {
            width = width - 20;
            height = width / aspectRatio;
        }

        return {
            width,
            height,
            isResponsible
        };
    }

    useEffect(() => {
        function handleResize() {
            console.log("handleResize")
            setVideoDimensions(getVideoDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const init = async () => {
            setLoadingText('Entrando na reunião');

            try {

                const { status, config } = await zoomMeetingConsulta(consultaId)


                if (!status) {
                    setLoadingText('Erro ao acessar reunião');
                    goBackPage()
                }

                await zmClient.init('en-US', `${window.location.origin}/lib`, {
                    enforceMultipleVideos: !window.crossOriginIsolated,
                    enforceVirtualBackground: !window.crossOriginIsolated,
                    stayAwake: true,
                    patchJsMedia: true,
                    leaveOnPageUnload: false
                });

                setIsRoomCreated(true)
                joinMeeting(config)

            } catch (err) {
                console.error("Error initializing zmClient")
                console.error(err);

                setLoadingText('Erro ao acessar reunião');
                goBackPage()
            }
            
        };
        init();
        return () => {
            ZoomVideo.destroyClient();
        };
    }, []);

    async function goBackPage(timeExecution = 1800) {
        setTimeout(() => {
            if (sessionStorage.getItem("tipo") == "A")
                window.location.href = "/ConsultasAdv"
            else {
                window.location.href = "/ConsultasCli"
            }
        }, timeExecution)
    }



    const zoomMeetingConsulta = async (consulta_id) => {
        try {

            const dataMeetingRetorno = await api.zoomMeetingConsulta({
                consulta_id
            });

            if(!dataMeetingRetorno.status) {
                return {
                    status: false
                };
            }

            const dataMeeting = {
                topic: dataMeetingRetorno.data.topic,
                token: dataMeetingRetorno.data.token,
                userName: userName,
                sessionPasscode: dataMeetingRetorno.data.sessionPasscode,
                features: ['video', 'audio', 'share', 'chat', 'users']
            }

            return {
                status: true,
                config: dataMeeting
            }
        } catch (e) {
            setIsLoading(false);
            setLoadingText('');
            console.error(e);
            return {
                status: false
            };
        }
    };


    const joinMeeting = async (config) => {

        try {

            await zmClient.join(
                config.topic, 
                config.token, 
                config.userName, 
                config.sessionPasscode
            ).catch((e) => {
                console.log("Erro Join session");
                console.log(e);

                throw new Error(e)
            });

            const stream = zmClient.getMediaStream();
            setMediaStream(stream);
            setIsVideo(stream.isStartShareScreenWithVideoElement());
            let userInfo = zmClient.getCurrentUserInfo();
            console.log('userInfo', userInfo)
            setVolume(stream.getUserVolumeLocally(userInfo.userId));
            setMyUserSession(userInfo);
            // setScreens([...screens, userInfo]);
            setIsLoading(false);
            setLoadingText('');
            setIsStartedMeeting(true)
        } catch (e) {
            setIsLoading(false);
            setLoadingText('');
            console.error(e);
        }

    };

    async function stopMeeting(endedMeeting = false) {
        zmClient.leave(endedMeeting)
        goBackPage(100)
    }

    function onStopMeeting() {
        setIsStartedMeeting(false)
        setIsStartedVideo(false)
        setIsStartedOtherVideo(false)
        setIsRoomCreated(false)
        setMediaStream(null)
        setMyUserSession(null)
        setOtherUserSession(null)
    }

    const isVideoActive = (userId) => {
        return videoActive.indexOf(userId) !== -1;
    }

    useEffect(() => {
        screens.forEach(async (item) => {
        console.log('Toogle worked');
        let user = zmClient.getUser(item.userId);
        console.log(user, isVideoActive(item.userId));
        if (user?.bVideoOn) {
            // await zmClient.getMediaStream().stopRenderVideo(
            //     document.querySelector('#my-self-view-canvas-' + item.userId),
            //      item.userId
            //     );
            //     setTimeout(async ()=>{
            if (document.querySelector('#my-self-view-canvas-' + item.userId)) {
                await zmClient.getMediaStream().renderVideo(
                    document.querySelector('#my-self-view-canvas-' + item.userId),
                    item.userId, 
                    1920, 
                    1080, 
                    0, 
                    0, 
                    3
                )
            }
            
                // }, 1200)
            
        } else if (!user?.bVideoOn) {
            // setTimeout(async ()=>{
            // await zmClient.getMediaStream().stopRenderVideo(
            //     document.querySelector('#my-self-view-canvas-' + item.userId),
            //      item.userId
            //     );
            // }, 500);
        }
        });
    }, [screens]);

    const onCameraClick = useCallback(async () => {

        console.log('Camera clicked');
        // let scr = [...screens];
        // let idx = scr.findIndex((item)=> item.userId == myUserSession.userId);
        if (isStartedVideo) {
            await mediaStream?.stopVideo()
                .catch(e => console.error(e));
            setIsStartedVideo(false);
            // scr[idx].bVideoOn = false;
        } else {
            await mediaStream.startVideo({ hd: true })
            setIsStartedVideo(true);
            // scr[idx].bVideoOn = true;
        }
        // setScreens([...screens]);
        

    }, [mediaStream, isStartedVideo, zmClient]);

    const onAudioClick = useCallback(async () => {
        const stream = zmClient.getMediaStream();
        if (isMutedAudio) {
            stream.unmuteAudio();
            setIsMutedAudio(false);
        } else {
            stream.muteAudio();
            setIsMutedAudio(true);
        }
    }, [mediaStream, isMutedAudio, zmClient]);

    const adjustVolumeLocally = useCallback(async (ev) => {
        //Vagner: No futuro caso queira que tenha mais do que 2 usuariow na chamada considerar implemnetar controle de audio pra cada um tambem
        const stream = zmClient.getMediaStream();
        let vol = stream.getUserVolumeLocally(zmClient.getSessionInfo().userId);
        if (vol == ev.target.value) return;
        let participants = zmClient.getAllUser();
        for (const user of participants) {
            if (user.userId !== zmClient.getSessionInfo().userId) {
                stream.adjustUserAudioVolumeLocally(user.userId, ev.target.value);
            }
        }
        
        setVolume(ev.target.value);
    }, [mediaStream, volume, zmClient]);

    const onVolumeClick = useCallback(async () => {
        const stream = zmClient.getMediaStream();
        if (volume > 0) setLastVolume('' + volume);
        let participants = zmClient.getAllUser();
        for (const user of participants) {
            if (user.userId !== zmClient.getSessionInfo().userId) {
                stream.adjustUserAudioVolumeLocally(user.userId, volume > 0 ? 0 : lastVolume);
            }
        }
        // console.log(volume, lastVolume);
        setVolume(volume > 0 ? 0 : lastVolume);
    }, [mediaStream, volume, zmClient]);


    // useEffect(() => {
    //     if (participants.length > 0) {
    //         let pageParticipants = []
    
    //         if (participants.length === 1) {
    //             pageParticipants = participants;
    //         } else {
    //             pageParticipants = participants
    //                 .filter((user) => user.userId !== zmClient.getSessionInfo().userId)
    //                 .sort((user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn));

    //             const currentUser = zmClient.getCurrentUserInfo();

    //             if (currentUser) {
    //                 pageParticipants.splice(1, 0, currentUser);
    //             }
    //         }

    //         setVisibleParticipants(pageParticipants);
    //         const videoParticipants = pageParticipants.filter((user) => user.bVideoOn).map((user) => user.userId);
    //         setSubscribedVideos(videoParticipants);
    //     }
    // }, [zmClient, participants]);


    // const callbackStartVideo = useCallback(async (payload) => {

    //     let { stream } = payload;

    //     switch (stream.getType()) {
    //       case 'video':
    //         await stream.renderVideo(
    //             document.querySelector('#other-self-view-canvas'),
    //             stream.getUserId(),
    //             1920,
    //             1080,
    //             0,
    //             0,
    //             3
    //         )
    //         break
    //       case 'screen':
    //         // Este é um fluxo de vídeo de compartilhamento de tela
    //         break
    //       default:
    //         break
    //     }

    // }, [zmClient]);

    // const callbackListUsers = useCallback(async (updatedParticipants) => {

    //     const currentUser = zmClient.getCurrentUserInfo();
        
    //     let userParticipant = null

    //     for (const user of updatedParticipants) {
    //         if (user.userId !== currentUser.userId) {
    //             userParticipant = user;

    //             if (userParticipant.userIdentity) {
    //                 setOtherUserSession(userParticipant)
    //             }
                
    //             if (userParticipant.bVideoOn) {

    //                 const stream = zmClient.getMediaStream();

    //                 await stream.renderVideo(
    //                     document.querySelector('#other-self-view-canvas'),
    //                     userParticipant.userId,
    //                     1920,
    //                     1080,
    //                     0,
    //                     0,
    //                     3
    //                 )

    //                 setIsStartedOtherVideo(true)
    //             } else {
    //                 setIsStartedOtherVideo(false)
    //             }

    //         }
    //     }
    
    // }, [zmClient]);

    const shareScreen = useCallback(async () => {

        try {
            const stream = zmClient.getMediaStream();
            if (!isSharing){
                if (stream.isStartShareScreenWithVideoElement()) {
                    setIsVideo(true);
                    setTimeout(async ()=> {
                        try{
                            await stream.startShareScreen(
                                shareVideoRef.current
                            )
                        } catch(err){
                            console.log(err);
                            setIsSharing(false);
                        }
                    }, 500)
                    
                    // screen share successfully started and rendered
                } else {
                    setIsVideo(false)
                    setTimeout(async ()=> {
                        try{
                            await stream.startShareScreen(
                                shareCanvasRef.current
                            )
                        } catch(err){
                            console.log(err);
                            setIsSharing(false);
                        }
                    }, 500)
                    // screen share successfully started and rendered
                }
                document.querySelector('#share-container-view').style.display = 'flex';
                setIsSharing(zmClient.getCurrentUserInfo().userId);
            }
                
            else {
                await stream.stopShareScreen();
                document.querySelector('#share-container-view').style.display = 'none';
                setIsSharing(false);
            }
            
        } catch (err) {
            console.error(err)
        }
    
    }, [zmClient, isSharing]);


    const updateScreens = async () => {
        let scr = [];
        const currentUser = zmClient.getCurrentUserInfo();
        // scr.unshift(currentUser);
        let participants = zmClient.getAllUser();
        for (const user of participants) {
            if (user.userId !== currentUser?.userId) {
                if (!user.isInFailover){
                    scr.unshift(zmClient.getUser(user.userId));
                }
                
            } else {
                scr.push(zmClient.getUser(user.userId));
            }
        }
        console.log(participants);
        
        setScreens(scr);
        return;
    }

    const stopVideos = async () => {
        for(let i = 0; i < screens.length; i++) {
            let item = screens[i];
            console.log('Toogle worked');
            // let user = zmClient.getUser(item.userId);
            if (item.bVideoOn) {
                // setTimeout(async ()=>{
                await zmClient.getMediaStream().stopRenderVideo(
                    document.querySelector('#my-self-view-canvas-' + item.userId),
                    item.userId
                    );
            }
        }
    }


    const callbackUserAdded = useCallback(async (updatedParticipants) => {
        console.log("=====================")
        console.log("user-added")
        console.log("updatedParticipants")
        console.log(updatedParticipants)
        // setVideoActive([]);
        await stopVideos();
        setTimeout(()=> {
            updateScreens();
        }, 1000);
    
    }, [zmClient, screens]);

    const callbackUserRemoved = useCallback(async (updatedParticipants) => {
        console.log("=====================")
        console.log("user-removed")
        console.log("updatedParticipants")
        console.log(updatedParticipants);
        await stopVideos();
        setTimeout(()=> {
            updateScreens();
        }, 1000);
    
    }, [zmClient, screens]);

    const callbackUserUpdated = useCallback(async (updatedParticipants) => {
        console.log("=====================")
        console.log("user-updated")
        console.log("updatedParticipants")
        console.log(updatedParticipants)
        await stopVideos();
        await updateScreens();
    }, [zmClient, screens]);

    useEffect(() => {
        zmClient.on('user-added', callbackUserAdded);
        zmClient.on('user-removed', callbackUserRemoved);
        zmClient.on('user-updated', callbackUserUpdated);
        return () => {
          zmClient.off('user-added', callbackUserAdded);
          zmClient.off('user-removed', callbackUserRemoved);
          zmClient.off('user-updated', callbackUserUpdated);
        };
    }, [zmClient, callbackUserAdded, callbackUserRemoved, callbackUserUpdated]);

    const callbackUserUpdatedVideo = useCallback(async (payload) => {
        console.log("=====================")
        console.log("peer-video-state-change")
        console.log("callbackUserUpdatedVideo")

        console.log(payload)

        // const stream = zmClient.getMediaStream();
        // let scr = [...screens];
        // let idx = scr.findIndex((item)=> item.userId == payload.userId);
        // if (idx == -1) return;
        // if (payload.action === 'Start') {
        //     scr[idx].bVideoOn = true;
        //   } else if (payload.action === 'Stop') {
        //     scr[idx].bVideoOn = false;
        //   }
        //   setScreens(scr);ez
        // console.log("=====================")
    }, [zmClient, screens]);

    useEffect(() => {
        zmClient.on('peer-video-state-change', callbackUserUpdatedVideo);
        return () => {
          zmClient.off('peer-video-state-change', callbackUserUpdatedVideo);
        };
    }, [zmClient, callbackUserUpdatedVideo]);


    useEffect(() => {
        async function startAudio() {
            if (isStartedMeeting) {
                let stream = null;
    
                if (mediaStream) {
                    stream = mediaStream
                } else {
                    stream = zmClient.getMediaStream();
                }
    
                if (isStartedAudio) {
                    await stream?.stopAudio()
                    setIsStartedAudio(false);
                } else {
    
                    var isSafari = window.safari !== undefined
            
                    if(isSafari) {
                        if(isStartedAudioEncode && isStartedAudioDecode){
                            await stream?.startAudio({
                                mute: true,
                            }).catch(e => console.error(e));
                            setIsStartedAudio(true);
                        } else {
                            console.log('safari audio has not finished initializing')
                        }
                    } else {
                        await stream?.startAudio({
                            mute: true,
                        })
                        setIsStartedAudio(true);
                    }
                }
            }
        }
        startAudio()
    }, [isStartedMeeting]);

    useEffect(() => {
        zmClient.on('connection-change', (payload) => {
            console.log('connection-change')

            if(payload.state === 'Closed') {
                console.log('Closed')
                // onStopMeeting()
                stopMeeting(true)
              // session ended by the host or the SDK kicked the user from the session (use payload.reason to see why the SDK kicked the user)
            } else if(payload.state === 'Reconnecting') {
                console.log('Reconnecting')
              // the client side has lost connection with the server (like when driving through a tunnel)
              // will try to reconnect for a few minutes
            } else if(payload.state === 'Connected') {
                console.log('Connected')
              // SDK reconnected the session after a reconnecting state
            } else if(payload.state === 'Fail') {
                console.log('Fail')
              // session failed to reconnect after a few minutes
              // user flushed from Zoom Video SDK session
            }
        })
    }, [zmClient]);

    useEffect(() => {
        zmClient.on('media-sdk-change', (payload) => {
            console.log('media-sdk-change')
            console.log(payload)

            if (payload.type === 'audio' && payload.result === 'success') {
              if (payload.action === 'encode') {
                setIsStartedAudioEncode(true)
              } else if (payload.action === 'decode') {
                setIsStartedAudioDecode(true)
              }
            }
          })
    }, [zmClient]);


    const onActiveShareChange = useCallback(async (payload) => {
        console.log("active-share-change")
            console.log(payload)
            if (payload.userId != zmClient.getCurrentUserInfo()?.userId) {
                if (payload.state === 'Active') {
                    setIsVideo(false);
                    // setTimeout(()=>{
                    zmClient.getMediaStream().startShareView(
                        shareCanvasRef.current,
                        payload.userId
                    )
                    // }, 500);
                    
                    
                    document.querySelector('#share-container-view').style.display = 'flex';
                    setIsSharing(payload.userId)
                } else if (payload.state === 'Inactive') {
                    zmClient.getMediaStream().stopShareView();
                    document.querySelector('#share-container-view').style.display = 'none';
                    setIsSharing(false);
                }
            }
    }, [zmClient, isSharing]);

    useEffect(() => {
        zmClient.on('active-share-change', onActiveShareChange);
        return () => {
            zmClient.off('active-share-change', onActiveShareChange);
          }
    }, [zmClient, onActiveShareChange]);
    
    useEffect(() => {
        zmClient.on('host-ask-unmute-audio', (payload) => {
            console.log(payload)
            console.log('Host asked me to unmute')
        })

        zmClient.on('active-speaker', (payload) => {
            console.log('Active speaker, use for CSS visuals', payload) // new active speaker, for example, use for microphone visuals, css video border, etc.
        })
    }, [zmClient]);

    return (
        <>
            {loading && <LoadingLayer content={loadingText} />}

            {/* <h1>MEETING</h1> */}

            <div 
                className="viewport"
                style={{
                    display: !loading && isStartedMeeting ? 'flex' : 'none',
                }}
            >
                
                <div 
                    id="video-container"
                    style={{
                        flexWrap: videoDimensions.isResponsible ? 'wrap' : 'nowrap'
                    }}
                >
                    <div id="share-container-view"
                        style={{
                            display: !isSharing ? 'none' : 'flex'
                        }}
                        >
                        <canvas id="screen-share-content-canvas"
                            width="1920" 
                            height="1080" 
                            ref={shareCanvasRef} 
                            style={{
                                display: !isSharing || isVideo ? 'none' : 'flex',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />   
                        <video id="my-screen-share-content-video"
                            width="1920" 
                            height="1080" 
                            ref={shareVideoRef}
                            style={{
                                display: !isSharing || !isVideo ? 'none' : 'flex',
                                width: 'auto',
                                height: 'auto'
                            }}
                        ></video>      
                    </div>
                    <div id="user-videos"
                        style={{
                            flexWrap: videoDimensions.isResponsible ? 'wrap' : 'nowrap',
                            width: isSharing? '15vh' : '100%',
                        }}
                    >
                        
                    {screens.map((item, idx) => {
                        return <div id={"video-container-view-" + item.userId}
                        className='video-container-view'
                        >
                            {!item.bVideoOn ? <div 
                                id={"my-self-view-canvas-avatar-" + item.userId}
                                className='my-self-view-canvas-avatar'
                                style={{ 
                                    display: 'flex',
                                    width: !isSharing ? videoDimensions.width : '15vw',
                                    height:!isSharing ? videoDimensions.height : '10vw'
                                }}
                            >
                                <p id="my-avatar">{item.displayName}</p>
                            </div> : <canvas 
                                id={"my-self-view-canvas-" + item.userId}
                                className='my-self-view-canvas'
                                width="1920" 
                                height="1080" 
                                style={{
                                    display: 'flex',
                                    width: !isSharing ? videoDimensions.width : '15vw',
                                    height:!isSharing ? videoDimensions.height : '10vw'
                                }}
                            /> }
                            
                            
                               
                            
                        </div>
                    })}
                    </div>      
                </div>

                <div id="video-container-buttons">
                    {/* {!isStartedMeeting && (
                        <>
                            <Button onClick={() => {
                                setIsLoading(true);
                                setTimeout(() => joinMeeting("A"),100);
                            }}>
                                Entrar Advogado
                            </Button>
                            <Button onClick={() => {
                                setIsLoading(true);
                                setTimeout(() => joinMeeting("C"),100);
                            }}>
                                Entrar Cliente
                            </Button>
                        </>
                    )} */}
                    
                    {isStartedMeeting && (
                        <div className='content-buttons'>
                            <div>
                                <Icon 
                                    icon={ic_sensor_door_outline}
                                    size={32}
                                    onClick={() => stopMeeting(false)}
                                    alt="Sair da Reunião"
                                    style={{
                                        padding: 8,
                                        color: "#fff",
                                        backgroundColor: "#B10000",
                                        borderRadius: "50%",
                                        cursor:  "pointer"
                                    }}
                                />

                                <Icon 
                                    icon={!isSharing ? ic_screen_share : ic_stop_screen_share}
                                    size={32}
                                    onClick={() => !isSharing || isSharing == myUserSession.userId ? shareScreen() : null}
                                    
                                    alt="Compartilhar Tela"
                                    style={{
                                        padding: 8,
                                        color: "#fff",
                                        backgroundColor: isSharing ? (isSharing == myUserSession.userId ? "#ffc107" : "#888" ): "#0b5ed7",
                                        borderRadius: "50%",
                                        cursor:  "pointer"
                                    }}
                                />

                                <Icon 
                                    icon={isStartedVideo ? ic_videocam : ic_videocam_off}
                                    size={32}
                                    onClick={onCameraClick}
                                    alt={isStartedVideo ? "Desligar Vídeo" : "Ligar Vídeo"}
                                    style={{
                                        padding: 8,
                                        color: "#fff",
                                        backgroundColor: isStartedVideo ? "#0b5ed7" : "#888",
                                        borderRadius: "50%",
                                        cursor:  "pointer"
                                    }}
                                />

                                <Icon 
                                    icon={isMutedAudio ? ic_mic_off : ic_mic}
                                    size={32}
                                    onClick={onAudioClick}
                                    alt={isMutedAudio ? "Ligar Áudio" : "Desligar Áudio"}
                                    style={{
                                        padding: 8,
                                        color: "#fff",
                                        backgroundColor: isMutedAudio ? "#888" : "#0b5ed7",
                                        borderRadius: "50%",
                                        cursor:  "pointer"
                                    }}
                                />

                                <Icon 
                                    icon={volume == 0 ? ic_volume_mute : volume < 50 ? ic_volume_down : ic_volume_up}
                                    size={32}
                                    onClick={onVolumeClick}
                                    alt={volume == 0 ? "Mutar Volume" : "Ativar Volume"}
                                    style={{
                                        padding: 8,
                                        color: "#fff",
                                        backgroundColor: volume == 0 ? "#888" : "#0b5ed7",
                                        borderRadius: "50%",
                                        cursor:  "pointer"
                                    }}
                                />
                                <div className='zm-volume-range'>
                                    <input value={volume} onChange={adjustVolumeLocally} type="range" orient="vertical" class="form-range" min="0" max="100" id="volume"/>
                                </div>
                            </div>

                            {zmClient?.getCurrentUserInfo()?.isHost && (
                                <Button 
                                    onClick={() => stopMeeting(true)}
                                    variant='danger'
                                >
                                    Encerrar a Reunião
                                </Button>   
                            )}

                        </div>
                    )}
                    

                </div>
                
            </div>

            <div 
                className="viewport-error"
                style={{
                    display: !loading && !isStartedMeeting ? 'flex' : 'none',
                }}    
            >
                    
                <p>Não foi possível acessar a reunião</p>

                <Button 
                    onClick={() => goBackPage(0)}
                    variant="primary"
                >
                    Voltar
                </Button>
                
            </div>

        </>
    );
}

export default Meeting;
