import React, { useEffect, useState, useCallback} from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Container, Row, Col, FloatingLabel, Form, FormControl } from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import Btn from '../../atoms/Button';
import { Toasts } from '../../utils/Toasts';

import { PerfilService } from '../../../services/ClienteServices/PerfilService';


import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'; 



export const PerfilClienteSenha = ({data}) => {
  const api = new PerfilService();

  const [controlForm, setControlForm] = useState(false);
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [type2, setType2] = useState('password');
  const [icon2, setIcon2] = useState(eyeOff);
  const [type3, setType3] = useState('password');
  const [icon3, setIcon3] = useState(eyeOff);
  const [tokenRecaptcha, setTokenRecaptcha] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  let defaultOptionsToast = {
    code: '',
    message: '',
    visible: false
}

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);


  function fecharToast() {
    let defaultOptionsToast = {
        code: '',
        message: '',
        visible: false
    }
    setOptionsToast(defaultOptionsToast)
}
  
 
  const [id] = sessionStorage.getItem('id');


  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
        console.log('Recaptcha não lido ainda.');
        return;
    }
  
    setTokenRecaptcha(await executeRecaptcha('submit'));
    }, [executeRecaptcha]);
  
  
   
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

  

  const [values, setValues] = useState({
    id: '',
    senha: '',
    nova_senha: '',
    confirma_senha: '',
  });

  useEffect(() => {
      setControlForm(true);
  },[]);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const handleToggle2 = () => {
    if (type2 === 'password') {
      setIcon2(eye);
      setType2('text');
    } else {
      setIcon2(eyeOff);
      setType2('password');
    }
  };

  const handleToggle3 = () => {
    if (type3 === 'password') {
      setIcon3(eye);
      setType3('text');
    } else {
      setIcon3(eyeOff);
      setType3('password');
    }
  };

  const Schema1 = yup.object().shape({
    senha: yup.string().required('Informe sua senha atual.'),
    nova_senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/,
        'A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.'
      )
      .required('A nova senha é obrigatória.'),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref('nova_senha')], 'As senhas devem ser iguais.')
      .required('A confirmação de senha é obrigatória.'),
  });

  const handleSubmit = (values) => {
    
    api.postAlterarSenha(
      values.senha,
      values.nova_senha,
      values.confirma_senha,
      tokenRecaptcha
    ).then((data) => {
     if(data.status = true) {
      let optionsToast = {
        code: data.mensagem.codigo,
        message: data.mensagem.mensagem,
        visible: true
      }
      setOptionsToast(optionsToast)
    } else {
      
      let optionsToast = {
        code: "103",
        message: 'Deu tudo errado!',
        visible: true
      }
      setOptionsToast(optionsToast)
    }
  })
};

  const renderForm = () => {
    return (
      <Container className="mt-3 p-0">
        <Formik
          initialValues={values}
          validationSchema={Schema1}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="">
                <Col lg={4} className="pb-3">
                  <FloatingLabel label="* Digite sua senha atual">
                    <Field
                      type={type}
                      id="senha"
                      name="senha"
                      as={FormControl}
                      className={`form-control ${touched.senha && errors.senha ? 'is-invalid' : ''}`}
                      value={values.senha}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.senha && errors.senha}
                      placeholder="* Digite sua senha atual"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Col>
                <Col lg={4} className="pb-3">
                  <FloatingLabel label="Digite uma nova senha">
                    <Field
                      type={type2}
                      id="nova_senha"
                      name="nova_senha"
                      as={FormControl}
                      className={`form-control ${touched.nova_senha && errors.nova_senha ? 'is-invalid' : ''}`}
                      value={values.nova_senha}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.nova_senha && errors.nova_senha}
                      placeholder="Digite uma nova senha"
                    />
                    {touched.nova_senha && errors.nova_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.nova_senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle2}>
                      <Icon icon={icon2} size={25} />
                    </span>
                  </FloatingLabel>
                </Col>
                <Col lg={4} className="pb-3">
                  <FloatingLabel label="Confirme sua nova senha">
                    <Field
                      type={type3}
                      id="confirma_senha"
                      name="confirma_senha"
                      as={FormControl}
                      className={`form-control ${touched.confirma_senha && errors.confirma_senha ? 'is-invalid' : ''}`}
                      value={values.confirma_senha}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.confirma_senha && errors.confirma_senha}
                      placeholder="Confirme sua nova senha"
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle3}>
                      <Icon icon={icon3} size={25} />
                    </span>
                  </FloatingLabel>
                </Col>
                <Col lg={4}></Col>
                <Col lg={4}></Col>
              </Row>
              <Row>
                <Col className="pt-3">
                  <Btn type="primary-sm" text="Alterar senha" isSumbit={true}/>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    );
  };

  return (
    <Container>
      <Toasts 
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
       />
      {controlForm ? (
        <Row>
          <Col>{renderForm()}</Col>
        </Row>
      ) : null}
    </Container>
  );
};
