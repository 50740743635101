import "./styles.css";
import { useEffect, useState } from "react";
import Subtitle from "../../atoms/Subtitle";
import { Col, Container, Row, FloatingLabel } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Form from "react-bootstrap/Form";
import { Field, Formik } from "formik";
import InputArquivo from "../../molecules/InputArquivo";
import DropdownBtn from "../../atoms/DropdownBtn";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { CadastroService } from "../../../services/AdvogadoServices/CadastroService";
import { Toasts } from "../../utils/Toasts";
import InputMask from "react-input-mask";
import { FotoPerfilCadastroModal } from "../../molecules/FotoPerfilCadastroModal";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import CountdownTimer from "../../molecules/CountdownTimer ";
import { ImgModal } from "../../atoms/ImgModal";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import TermosDeUsoModal from "../../molecules/TermosDeUsoModal";
import PoliticaPrivacidadeModal from "../../molecules/PoliticaPrivacidadeModal";
import Paragraph from "../../atoms/Paragraph";

export const RestCadastroAdvogadoForms = ({ callBackCadastroRealizado }) => {
  let api = new CadastroService();

  const navigate = useNavigate();

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");

  const codigoCompleto = code1 + code2 + code3 + code4;

  const [defaultOab, setDefaultOab] = useState("");
  const [show, setShow] = useState(false);
  const [step, setStep] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const defineOabNumero = (event) => {
    setOabNumero(event.target.value);
  };
  const [fotoPerfil, setFotoPerfil] = useState();
  const [fotoOab, setFotoOab] = useState();
  const [oabNumero, setOabNumero] = useState();

  const validarCodigoOAB = (codigoOAB) => {
    const estados = {
      AC: "Acre",
      AL: "Alagoas",
      AP: "Amapá",
      AM: "Amazonas",
      BA: "Bahia",
      CE: "Ceará",
      DF: "Distrito Federal",
      ES: "Espírito Santo",
      GO: "Goiás",
      MA: "Maranhão",
      MT: "Mato Grosso",
      MS: "Mato Grosso do Sul",
      MG: "Minas Gerais",
      PA: "Pará",
      PB: "Paraíba",
      PR: "Paraná",
      PE: "Pernambuco",
      PI: "Piauí",
      RJ: "Rio de Janeiro",
      RN: "Rio Grande do Norte",
      RS: "Rio Grande do Sul",
      RO: "Rondônia",
      RR: "Roraima",
      SC: "Santa Catarina",
      SP: "São Paulo",
      SE: "Sergipe",
      TO: "Tocantins",
    };
    if (codigoOAB.length < 5) {
      return false;
    }
    const estadoCodigoOAB = codigoOAB.slice(0, 2).toUpperCase();
    if (estadoCodigoOAB in estados) {
      return true;
    } else {
      return false;
    }
  };

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [areasAdv, setAreasAdv] = useState([]);
  const [valorConsulta, setValorConsulta] = useState("");
  const [ambitoNacional, setAmbitoNacional] = useState(false);
  const [aceitoTermos, setAceitoTermos] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalPoliticaShow, setModalPoliticaShow] = useState(false);

  const handleTermosClick = () => {
    setModalShow(true);
  };

  const handlePoliticaClick = () => {
    setModalPoliticaShow(true);
  };

  const [dados, setDados] = useState({
    optionsArea: [],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const etapa_1 = sessionStorage.getItem("etapa_1");
    const etapa_2 = sessionStorage.getItem("etapa_2");
    const etapa_3 = sessionStorage.getItem("etapa_3");
    let defaultStep = 1;

    if (etapa_1 === "true") {
      defaultStep = 2;
    }
    if (etapa_2 === "true") {
      defaultStep = 3;
    }
    if (etapa_3 === "true") {
      defaultStep = 4;
    }
    if (etapa_1 === "true" && etapa_2 === "true" && etapa_3 === "true") {
      defaultStep = 4;
    }
    setStep(defaultStep);
  }, []);

  const handleSubmit = async (redirectAgenda) => {
    if (step === 1) {
      setIsLoading(true);
      if (!fotoPerfil) {
        let optionsToast = {
          code: "102",
          message: "Por favor, insira uma foto de perfil ",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading(false);
        return;
      }
      if (!oabNumero) {
        let optionsToast = {
          code: "102",
          message: "Número da OAB é obrigatório",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading(false);
        return;
      }
      if (validarCodigoOAB(oabNumero) === false) {
        let optionsToast = {
          code: "102",
          message: "Número da OAB inválido",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading(false);
        return;
      }
      if (!fotoOab) {
        let optionsToast = {
          code: "102",
          message: "Por favor, insira uma foto do documento OAB",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading(false);
        return;
      }
      try {
        const data = await api.postEnviaDocumentos(
          fotoPerfil,
          fotoOab,
          oabNumero
        );
        console.log(JSON.stringify(data));
        if (data.status) {
          sessionStorage.setItem("etapa_1", data.status);
          setIsLoading(false);
          nextStep();
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          setIsLoading(false);
          return false;
        }
      } catch (error) {
        console.log(">>>>>>>>>>>>>>>>>>>", error);
      }
    }

    if (step === 2) {
      setIsLoading2(true);
      if (!Array.isArray(areasAdv) || areasAdv.length === 0) {
        let optionsToast = {
          code: "102",
          message: "Por favor, selecione as áreas de atuação",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading2(false);
        return;
      }
      if (valorConsulta === "" || valorConsulta === null || !valorConsulta) {
        let optionsToast = {
          code: "102",
          message: "Valor da consulta é obrigatório",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading2(false);
        return;
      }
      if (aceitoTermos === false) {
        let optionsToast = {
          code: "102",
          message: "Por favor, aceite os termos de uso",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading2(false);
        return;
      }
      api
        .postAreasAtuacao(
          areasAdv,
          valorConsulta,
          ambitoNacional ? ambitoNacional : ""
        )
        .then((data) => {
          if (data.status === true) {
            sessionStorage.setItem("etapa_2", data.status);
            setIsLoading(true);
            nextStep();
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);
            setIsLoading(false);
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (step === 3) {
      setIsLoading3(true);
      if (!codigoCompleto) {
        let optionsToast = {
          code: "102",
          message: "Por favor, digite o código que enviamos.",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading3(false);
        return;
      }
      api
        .postValidaCodigoVerificador(codigoCompleto)
        .then((data) => {
          if (data.status === true) {
            sessionStorage.setItem("etapa_3", data.status);
            setIsLoading3(true);
            nextStep();
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);
            setIsLoading3(false);
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (step === 4) {
      api
        .postAceitaBoasVindas()
        .then((data) => {
          if (data.status === true) {
            sessionStorage.setItem("etapa_4", data.status);
            sessionStorage.setItem("etapa_5", data.status);
            if (redirectAgenda == true) {
              navigate("/ConsultasAdv");
            } else {
              navigate("/");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleValorConsulta = (value) => {
    setValorConsulta(value.replace(/\D/g, ""));
  };

  useEffect(() => {
    let API = new HomeService();
    API.getAll().then((data) => {
      if (data.status === true) {
        setDados(data);
      }
    });
  }, []);

  const handleItemClick = (value) => {
    setSelectedItem(value);
  };

  const addSelectedItemToList = () => {
    if (
      selectedItem &&
      !selectedItems.find((item) => item.desc === selectedItem.desc)
    ) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
      setAreasAdv((prevAreasAdv) => [...prevAreasAdv, selectedItem.id]);
    }
  };

  const removeSelectedItem = (item) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter(
        (selectedItem) => selectedItem.desc !== item.desc
      )
    );
    setAreasAdv((prevAreasAdv) =>
      prevAreasAdv.filter((selectedItem) => selectedItem !== item.id)
    );
  };

  const handleSair = () => {
    {
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  const handleNavegarAgenda = () => {
    const redirectAgenda = true;
    handleSubmit(redirectAgenda);
  };

  const handleNavegarHome = () => {
    {
      const redirectAgenda = false;
      handleSubmit(redirectAgenda);
      callBackCadastroRealizado(false);
    }
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleFotoPerfil = (base64Image) => {
    setFotoPerfil(base64Image); // Atualiza o estado com a imagem base64
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Container className="alinhamento-step">
              <Row className="">
                <Col className="text-center mb-5">
                  <Subtitle text="Precisamos de algumas informações para concluir o seu cadastro!" />
                  <Col className="d-lg-flex  justify-content-center ">
                    <Col
                      lg={4}
                      className="d-flex flex-column justify-content-center"
                    >
                      <AvatarPerfil
                        type="A"
                        base64={sessionStorage.getItem("fotoPerfil")}
                        width="12rem"
                        height="12rem"
                        padding="0.4rem"
                      />

                      <Btn
                        type={"secondary-sm"}
                        isSubmit={false}
                        text="Adicionar foto"
                        onTouchable={handleShow}
                      />
                    </Col>
                    <Col className=" d-flex justify-content-start flex-column">
                      <Col className="d-block">
                        <Formik
                          key={step}
                          onSubmit={(values) => {
                            setDefaultOab(values.oabNumero);
                            handleSubmit();
                          }}
                          initialValues={{
                            oabNumero: defaultOab,
                          }}
                        >
                          {({ handleSubmit, touched, errors }) => (
                            <Form
                              className="alinhamento-step"
                              noValidate
                              onSubmit={handleSubmit}
                            >
                              <Form.Group controlId="oabNumero">
                                <FloatingLabel
                                  label={"Número OAB (ex: SP01122)"}
                                >
                                  <Form.Control
                                    maxLength={15}
                                    className={`input-register-login-facj ${
                                      touched.oabNumero && errors.oabNumero
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    type="text"
                                    name="oabNumero"
                                    value={oabNumero}
                                    onChange={defineOabNumero}
                                    isValid={
                                      touched.oabNumero && !errors.oabNumero
                                    }
                                    placeholder="oabNumero"
                                  />
                                  {touched.oabNumero && errors.oabNumero && (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      tooltip
                                    >
                                      {errors.oabNumero}
                                    </Form.Control.Feedback>
                                  )}
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.oabNumero}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form>
                          )}
                        </Formik>
                      </Col>

                      <Col>
                        <Col className="d-flex flex-column ms-3">
                          <ParagraphSmall
                            text="Insira a foto de sua OAB ou outro documento:"
                            textSize="14px"
                          />

                          <InputArquivo
                            validFileExtensions={[".jpg", ".png", ".jpeg"]}
                            getFile={(e) => setFotoOab(e)}
                            name={"(Formatos: PNG/JPG/JPEG)"}
                            id={"fotoOab"}
                          />
                        </Col>
                      </Col>
                    </Col>
                  </Col>
                </Col>

                <FotoPerfilCadastroModal
                  handleFotoPerfil={handleFotoPerfil}
                  show={show}
                  handleClose={handleClose}
                  setShow={setShow}
                />
              </Row>

              <Col className="mb-4">
                <ProgressBar
                  now={60}
                  label={`${60}%`}
                  animated={isLoading}
                  className=""
                />
              </Col>

              <Col className="d-lg-flex   mb-2 justify-content-between">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Sair"
                  onTouchable={handleSair}
                />
                {isLoading ? (
                  <Button disabled className="botao-sec-disable">
                    <Spinner size="sm" className="align-itens-center"></Spinner>
                  </Button>
                ) : (
                  <Button
                    type={"button"}
                    className={"botao-secundario"}
                    onClick={handleSubmit}
                  >
                    Próximo
                  </Button>
                )}
              </Col>
            </Container>
          </>
        );

      case 2:
        return (
          <Formik
            initialValues={{ valor: valorConsulta }}
            onSubmit={handleSubmit}
          >
            {({ touched, errors }) => (
              <Form className="alinhamento-step" noValidate>
                <Container>
                  <Col className="d-flex justify-content-center">
                    <Col lg={10} className="text-center mb-3">
                      <Subtitle text="Agora nos informe em quais áreas você irá atuar no Faça Jus!" />
                    </Col>
                  </Col>
                  <Row className="mt-2">
                    <Col lg={9} className="dropdown-index">
                      <DropdownBtn
                        text="Selecione a área"
                        type={"primary"}
                        options={dados.optionsArea}
                        onPress={(data) => handleItemClick(data)}
                      />
                    </Col>
                    <Col className="d-flex align-items-center" lg={3}>
                      <Btn
                        type={"terciary-sm"}
                        isSubmit={false}
                        text="Adicionar"
                        onTouchable={addSelectedItemToList}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between px-2">
                    {selectedItems.map((item, index) => (
                      <Col
                        lg={12}
                        key={index}
                        className="card-itens d-flex align-items-center justify-content-between m-1"
                      >
                        {item.desc}
                        <Btn
                          type={"primary-X"}
                          isSubmit={false}
                          text="X"
                          onTouchable={() => removeSelectedItem(item)}
                        />
                      </Col>
                    ))}

                    <Col className="text-center mb-2 mt-2">
                      <Form.Group controlId="valor">
                        <FloatingLabel label="Valor por consulta">
                          <InputMask
                            mask="R$ 999"
                            id="valor"
                            name="valor"
                            className={`form-control ${
                              touched.valor && errors.valor ? "is-invalid" : ""
                            }`}
                            onChange={(e) =>
                              handleValorConsulta(e.target.value)
                            }
                            value={valorConsulta}
                          ></InputMask>
                          {touched.valor && errors.valor && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.valor}
                            </Form.Control.Feedback>
                          )}

                          <p className="fw-bold text-lg-start mt-2">
                            * Consulte a tabela da OAB.
                          </p>
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.valor}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Row>
                      <Col className="ps-3 mt-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Desejo atuar em âmbito Nacional. (Esta opção fará com que
                          você apareça nas buscas de clientes por todo o Brasil)"
                          checked={ambitoNacional}
                          onChange={(e) => setAmbitoNacional(e.target.checked)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="d-flex align-items-center">
                        <Form.Check
                          type="switch"
                          id="custom-switch2"
                          checked={aceitoTermos}
                          onChange={(e) => setAceitoTermos(e.target.checked)}
                        />
                        <Col>
                          <span>Li e concordo com os </span>
                          <span
                            className="link-color"
                            style={{ cursor: "pointer" }}
                            onClick={handleTermosClick}
                          >
                            <strong>Termos de uso</strong>
                          </span>
                          <span> e a </span>
                          <span
                            className="link-color"
                            style={{ cursor: "pointer" }}
                            onClick={handlePoliticaClick}
                          >
                            <strong>Política de Privacidade.</strong>
                          </span>
                        </Col>
                      </Col>
                    </Row>

                    {modalShow && (
                      <TermosDeUsoModal
                        size="lg"
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    )}
                    {modalPoliticaShow && (
                      <PoliticaPrivacidadeModal
                        size="lg"
                        show={modalPoliticaShow}
                        onHide={() => setModalPoliticaShow(false)}
                      />
                    )}
                  </Row>

                  <Col className="mt-2 mb-4">
                    <ProgressBar
                      now={80}
                      label={`${80}%`}
                      animated={isLoading2}
                      className=""
                    />
                  </Col>

                  <Row className="mt-2 mb-5">
                    <Col className="d-lg-flex gap-2 justify-content-between">
                      <Btn
                        type={"terciary"}
                        isSumbit={false}
                        text="Sair"
                        onTouchable={handleSair}
                      />
                      {isLoading2 ? (
                        <Button disabled className="botao-sec-disable">
                          <Spinner
                            size="sm"
                            className="align-itens-center"
                          ></Spinner>
                        </Button>
                      ) : (
                        <Button
                          type={"button"}
                          className={"botao-secundario"}
                          onClick={handleSubmit}
                        >
                          Próximo
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        );

      case 3:
        return (
          <Container className="alinhamento-step ">
            <Col className="d-flex justify-content-center">
              <Col className="text-center mb-3">
                <Subtitle text="Por fim, precisamos validar sua conta!" />
                <Col className="d-flex justify-content-center">
                  <ParagraphSmall
                    text="Por favor, digite abaixo o código de verificação 
              enviado ao seu celular via SMS e/ou E-mail cadastrado."
                  />
                </Col>
              </Col>
            </Col>

            <Row className="d-flex flex-column">
              <Col>
                <Formik
                  initialValues={{
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                  }}
                  onSubmit={() => handleSubmit(codigoCompleto)}
                >
                  {({ values, handleChange }) => (
                    <Form className="" noValidate>
                      <Col className="d-flex gap-3 ">
                        <Field
                          key={1}
                          type="text"
                          name="code1"
                          value={values.code1}
                          onChange={(e) => {
                            handleChange(e);
                            setCode1(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={2}
                          type="text"
                          name="code2"
                          value={values.code2}
                          onChange={(e) => {
                            handleChange(e);
                            setCode2(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={3}
                          type="text"
                          name="code3"
                          value={values.code3}
                          onChange={(e) => {
                            handleChange(e);
                            setCode3(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={4}
                          type="text"
                          name="code4"
                          value={values.code4}
                          onChange={(e) => {
                            handleChange(e);
                            setCode4(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col className="d-flex justify-content-center">
                <Col className="d-flex flex-column gap-2 align-items-center justify-content-center">
                  <Col>
                    <CountdownTimer initialTime={240} />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row className="d-flex flex-column gap-3 mb-3">
              <Col>
                <ProgressBar now={100} striped={isLoading3} label={`${100}%`} />
              </Col>
              <Col>
                <ParagraphSmall
                  textAlign="center"
                  textSize="13px"
                  text="Em caso de dúvidas entre em contato com o 
                 <strong><a  target=blank href='https://api.whatsapp.com/send?phone=554199934379&text=Ol%C3%A1,%20vi%20seu%20contato%20no%20site%20e%20gostaria%20de%20saber%20mais.'>Atendimento</a>.</strong>"
                />
              </Col>
              <Col className="d-lg-flex gap-2 justify-content-between">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Sair"
                  onTouchable={handleSair}
                />
                {isLoading3 ? (
                  <Button disabled className="botao-sec-disable">
                    <Spinner size="sm" className="align-itens-center"></Spinner>
                  </Button>
                ) : (
                  <Button
                    type={"button"}
                    className={"botao-secundario"}
                    onClick={handleSubmit}
                  >
                    Próximo
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        );

      case 4:
        return (
          <Container className="alinhamento-step ">
            <Col className="d-flex justify-content-center">
              <Subtitle text="Seu perfil foi criado com sucesso!" />
            </Col>
            <Col className="d-flex justify-content-center">
              <ImgModal type="imagem3" />
            </Col>
            <Col>
              <ParagraphSmall
                textAlign="center"
                text="Para que os clientes possam visualizar seu perfil, você precisa acessar
                a seção de agendamentos para: <br><br>
                <Strong> 1. </Strong> Cadastrar seus dados bancários para receber pelas consultas.<br>
                <Strong> 2. </Strong> Cadastrar uma agenda de horários de atendimento.
                <br><br>
                Sua conta cadastrada será verificada pela equipe Faça Jus.<br>
                Sua agenda ficará disponível aos clientes, após a nossa verificação.<br><br>
                Mas não se preocupe! Enquanto isso, você pode utilizar e navegar normalmente em nossa plataforma.
                <br><br>"
              />
            </Col>
            <Col className="d-flex justify-content-center gap-4">
              <Btn
                type={"terciary-sm"}
                isSubmit={false}
                text="Fechar"
                onTouchable={handleNavegarHome}
              />
              <Btn
                type={"secondary-sm"}
                isSubmit={false}
                text="Crie sua agenda"
                onTouchable={handleNavegarAgenda}
              />
            </Col>
          </Container>
        );
    }
  };

  return (
    <>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      {renderForm()}
    </>
  );
};
