import functions from "../../components/utils/functions";
import api from "../Api";

export class FinanceiroService {

  async cadastrarMeioRecebimento(values) {
    try {
      //  if (chavePix) {
      //   const response = await api.post("/advogado/cadastrar-meio-recebimento", 
      //     {
      //       'selectedOption': values.selectedOption,
      //       'nomeChavePix': values.nomeChavePix,
      //       'chavePix': values.chavePix,
      //       'cli_id': values.id,
      //     },
      //     {
      //       headers: {
      //         "Content-type": "application/json",
      //         Authorization: "Bearer " + sessionStorage.getItem("token"),
      //       },
      //     }
      //   );
      //   return response.data;
      // } else {
        const response = await api.post(
          "/advogado/cadastrar-meio-recebimento", 
          {
            'selectedOption': values.selectedOption,
            'codigoBanco': values.codigoBanco,
            'agencia': values.agencia,
            'contaCorrente': values.contaCorrente,
            'nome': values.nome,
            'cpfOrCnpj': values.cpfOrCnpj,
            'cpf': values.cpf,
            'cnpj': values.cnpj
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        );
        return response.data;
      // }
    } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } else {
          throw error; 
        }
      }
  }
  

  async exibirMeioRecebimento() {
    try {
      const response = await api.post("/advogado/exibir-meio-recebimento", {
      },{
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
  
  
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } else {
        throw error; 
      }
    }
  }

  async verificarMeioRecebimento() {
    try {
      const response = await api.post("/advogado/verificar-meio-recebimento", {
      },{
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
  
  
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } else {
        throw error; 
      }
    }
  }

  async extratoConsultaAdvogado() {
    try {
      const response = await api.post("/advogado/extrato-consulta-adv", {

      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } else {
        throw error; 
      }
    }
  }

  async extratoFinanceiroAdvogado() {
    try {
      const response = await api.post("/advogado/extrato-financeiro-adv", {
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  async consultarMensalidade() {
    try {
      const response = await api.post("/advogado/consultar-mensalidade", {
        // "cli_id": id,
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  

 
}
  




  
