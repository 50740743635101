import functions from "../../components/utils/functions";
import api from "../Api";

export class ForumService {
    async chatAdvogado(tokenRecaptcha) {
        try {
          const response = await api.post("/advogado/listar-chat-advogado", {
            "tokenRecaptcha": tokenRecaptcha
          },{
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            });
                     
          return response.data;
        } catch (error) {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } else {
            throw error; 
          }
      }
    }

    async mensagemAdvogado(
        // cac_add_id, 
        cac_cli_id) 
        
        {  try {
          const response = await api.post("/advogado/listar-mensagem-advogado", {
            // "addId": cac_add_id,
            "cliId": cac_cli_id
          }, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          });
      
          return response.data;
        } catch (error) {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } else {
            throw error; 
          }
      }
      }

      async salvarNovaMensagemCliente(
        cac_cli_id,
        mensagemCliente
        
      ) { return await api.post("/advogado/enviar-mensagem-advogado", {
        "cac_cli_id": cac_cli_id,
        "mensagem" : mensagemCliente,
       
        }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        }).then((res) => res.data)
          .catch((error) => {
            if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
              functions.modalToken();
            } 
          });
      }

      async enviaPerguntaForumAdvogado(
        pergunta,       
      ) {
       
        return await api.post("/advogado/envia-pergunta-forum-advogado", {
          "pergunta": pergunta
           
        }, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
          .then((res) =>  res.data)
          .catch((error) => {
            if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
              functions.modalToken();
            } 
          });
      }

      async verPerguntaForumAdvogado(
        idPergunta,
        dataHora,
        mensagem,
        areaAtuacao
      ) {
        const params = {
          idPergunta: idPergunta,
          dataHora: dataHora,
          mensagem: mensagem,
          areaAtuacao: areaAtuacao
        };
      
        const headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        };
      
        return await api.get("/advogado/ver-pergunta-forum-advogado", {
          params: params,
          headers: headers
        })
        .then((res) => res.data)
        .catch((error) => {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } 
        });
      }
      


      async enviaRespostaForumAdvogado(
        id,
        idPergunta,
        resposta
      ) {
        return await api.post("/advogado/envia-resposta-forum-advogado", {
          'id' : id,
          'idPergunta' : idPergunta,
          'resposta' :  resposta
        }, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          }
        })
          .then((res) => res.data)
          .catch((error) => {
            if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
              functions.modalToken();
            } 
          });
      }


      async verRespostaForumAdvogado(
        idPergunta
      ){
        return await api.post("/advogado/ver-resposta-forum-advogado", {
          'idPergunta' : idPergunta
        }, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          }
        })
        .then((res) => res.data)
        .catch((error) => {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } 
        });
      }
}