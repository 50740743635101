import api from "../Api";

export class faleConoscoService {
  async postFaleConosco(nome, telefone, email, mensagem, opcaoContato) {
    return await api
      .post(
        "/faleconosco/envia-mensagem",
        {
          nome: nome,
          telefone: telefone,
          email: email,
          mensagem: mensagem,
          opcaoContato: opcaoContato,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => res.data);
  }

  async verPerguntaForum(idPergunta, dataHora, mensagem, areaAtuacao) {
    return await api
      .get(
        "/faleconosco/ver-pergunta-forum",
        {
          idPergunta: idPergunta,
          dataHora: dataHora,
          mensagem: mensagem,
          areaAtuacao: areaAtuacao,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => res.data);
  }

  async postEnviaRespostaForum(id, idPergunta, resposta) {
    return await api
      .post(
        "/faleconosco/envia-resposta-forum",
        {
          id: id,
          idPergunta: idPergunta,
          resposta: resposta,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => res.data);
  }

  async postVerRespostaForum(
    idPergunta,
    idAdvogado,
    idCliente,
    resposta,
    numeroOab,
    foto,
    numeroEstrelas,
    nomeAdvogado
  ) {
    return await api
      .post(
        "/faleconosco/ver-resposta-forum",
        {
          idPergunta: idPergunta,
          idAdvogado: idAdvogado,
          idCliente: idCliente,
          resposta: resposta,
          numeroOab: numeroOab,
          foto: foto,
          numeroEstrelas: numeroEstrelas,
          nomeAdvogado: nomeAdvogado,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data);
  }
}
