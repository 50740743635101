import functions from "../../components/utils/functions";
import api from "../Api";

export class AgendaService {
  async listarAgenda() {
    return await api
      .post(
        "/advogado/listar-agenda",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }
  async excluirHorario(idAdv) {
    return await api
      .post(
        "/advogado/excluir-horario",
        {
          idAdv
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async criarAgenda(duracao, horarioAtendimento, replicarAgenda, aceitaTermos) {
    return await api
      .post(
        "/advogado/criar-agenda",
        {
          duracao: duracao,
          horarioAtendimento: horarioAtendimento,
          replicarAgenda: replicarAgenda,
          aceitaTermos: aceitaTermos, 
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirAgenda() {
    return await api
      .post(
        "/advogado/excluir-agenda",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

}
