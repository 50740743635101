import api from "../Api";

export class ZoomServices {
    async getLinks() {
      return await api
        .post("/videoconferencia/video-conferencia-link", {
          
          // headers: {
          //   "Content-type": "application/json",
          //   Authorization: "Bearer " + sessionStorage.getItem("token"),
          // },
          
          
        })
        .then((res) => res.data);
    }

    async getUsers() {
      return await api
        .get("/videoconferencia/listar-usuario",{
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }) 
        .then((res) => res.data);
    }

    async createUser(nome,email,tel) {
      return await api 
        .post("/videoconferencia/criar-usuario",
        {
          nome : nome,
          email : email,
          tel: tel
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => res.data);
    }

    async createGroup(guid,name,member1,member2) {
      return await api
        .post("/videoconferencia/gerar-url-video-conferencia",
        {
          guid : guid,
          name : name,
          member1 : member1,
          member2 : member2
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => res.data);
    }
    
    async getGroup() {
      return await api
        .get("/videoconferencia/listar-grupo",
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) =>res.data);
    }

    async addMembro(guid,member1,member2) {
      return await api
        .post("/videoconferencia/adicionar-membro-conferencia",
        {
          guid : guid,
          member1 : member1,
          member2 : member2
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) =>res.data);
    }

    async generateToken({ consultaId, topic, role, userIdentity }) {
      return await api
        .post("/videoconferencia/generate",
        {
          consultaId,
          topic,
          role,
          userIdentity
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) =>res.data);
    }

    async zoomMeetingConsulta({ consulta_id }) {
      return await api
        .post("/videoconferencia/zoom-meeting-consulta",
        {
          cac_id: consulta_id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) =>res.data);
    }
    
  }

