import React, { useEffect, useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, FloatingLabel, Form } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import { HomeService } from "../../../services/HomeServices/HomeService";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import MultiDropdown from "../../atoms/MultiDropdown";
import Btn from "../../atoms/Button";
import Icons from "../../atoms/Icons";
import image4 from "../../../../src/img/conversa.png";
import { ForumService } from "../../../services/ClienteServices/ForumService";
import * as formik from "formik";
import * as yup from "yup";
import { Toasts } from "../../utils/Toasts";

export const PerguntarForumCli = () => {
  let ApiForum = new ForumService();
  let ApiHome = new HomeService();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Formik } = formik;
  const [aceitoTermos, setAceitoTermos] = useState(false);
  const [envio, setEnvio] = useState(false);
  const [option, setOption] = useState({});
  const [filterText, setFilterText] = useState("");
  const [areaSelecionada, setAreaSelecionada] = useState(null);
  const [dados, setDados] = useState({
    optionsArea: [],
  });

  useEffect(() => {
    ApiHome.getAll().then((data) => {
      if (data.status === true) {
        const formattedOptions = renderDropdownOptions(data.optionsArea);
        setDados({
          optionsArea: formattedOptions,
        });
      }
    });
  }, []);

  const renderDropdownOptions = (areas) => {
    return areas.map((area) => ({
      id: area.id,
      desc: area.desc,
      subOptions: area.area_frases.map((frase) => ({
        id: frase.id,
        desc: frase.desc,
      })),
    }));
  };

  const defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const fecharToast = () => {
    setOptionsToast(defaultOptionsToast);
  };

  const schema1 = yup.object().shape({
    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido."),
    pergunta: yup.string().required("Digite sua pergunta"),
  });

  const handleItemClick = (option) => {
    setOption(option);
  };

  const [formValues, setFormValues] = useState({});
  const handleSubmit = (values) => {
    if (!aceitoTermos) {
      setOptionsToast({
        code: "102",
        message: "Aceite os termos para prosseguir",
        visible: true,
      });
      return;
    }

    if (!option.id) {
      setOptionsToast({
        code: "102",
        message: "Selecione uma área de atuação",
        visible: true,
      });
      return;
    }

    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setEnvio(!envio);
    ApiForum.postForumCliente(
      option.id,
      values.pergunta,
      values.email,
      aceitoTermos
    )
      .then((data) => {
        setOptionsToast({
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderForm = () => {
    return (
      <Formik
        key={envio}
        validationSchema={schema1}
        initialValues={{
          pergunta: "",
          email: "",
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Container>
              <Row className="justify-items-start align-items-center gap-2">
                <Paragraph text="Tire suas dúvidas sobre seu caso com especialistas" />
                <Col lg={6} className="render-index">
                  <MultiDropdown
                    text="Selecione a área"
                    options={dados.optionsArea}
                    onPress={handleItemClick}
                    selected={areaSelecionada}
                    filterText={filterText}
                    setFilterText={setFilterText}
                    onClearFilter={() => {
                      setFilterText("");
                      setAreaSelecionada(null);
                    }}
                  />
                </Col>
              </Row>

              <Container className="d-flex bg-box-grey shadow-sm rounded align-items-center justify-content-center my-5 py-1">
                <Row>
                  <Col lg={4}>
                    <Col className="pt-2 d-flex align-items-center justify-content-center">
                      <Col lg={2}>
                        <Icons type="relogio-azul" />
                      </Col>
                      <Col>
                        <Paragraph text="Tempo de resposta aproximadamente 48h" />
                      </Col>
                    </Col>
                  </Col>
                  <Col lg={4}>
                    <Col className="pt-2 d-flex align-items-center justify-content-center">
                      <Col lg={2}>
                        <Icons type="check-azul" />
                      </Col>
                      <Col lg={7}>
                        <Paragraph text="Serviço gratuito" />
                      </Col>
                    </Col>
                  </Col>
                  <Col lg={4}>
                    <Col className="pt-2 d-flex align-items-center justify-content-center">
                      <Col lg={2}>
                        <Icons type="perfil-azul" />
                      </Col>
                      <Col lg={9}>
                        <Paragraph text="A sua pergunta será publicada de forma anônima" />
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Container>

              <Form.Group controlId="pergunta">
                <FloatingLabel label="Escreva aqui sua dúvida" className="mb-3">
                  <Form.Control
                    className={`margim-campos-formulario ${
                      touched.pergunta && errors.pergunta ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="pergunta"
                    maxLength={500}
                    value={values.pergunta}
                    onChange={handleChange}
                    isValid={touched.pergunta && !errors.pergunta}
                    placeholder="pergunta"
                    as="textarea"
                    style={{ height: "200px" }}
                  />
                  {touched.pergunta && errors.pergunta && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.pergunta}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.pergunta}
                </Form.Control.Feedback>
              </Form.Group>

              <Row className="my-5 gap-3">
                <Col lg={3}>
                  <img src={image4} alt="Pessoas Conversando" />
                </Col>
                <Col lg={5}>
                  <Form.Group controlId="email">
                    <FloatingLabel label="Digite seu email" className="mb-3">
                      <Form.Control
                        className={`margim-campos-formulario ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        placeholder="Email"
                      />
                      {touched.email && errors.email && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <ParagraphSmall text="Iremos utilizá-lo para notificar sobre sua resposta, lembrando que não será publicada online." />
                  <Col className="d-flex pt-2">
                    <Col lg={9}>
                      <ParagraphSmall
                        textAlign="start"
                        textSize="13px"
                        text="Li e concordo com a: <strong><a target='_blank' href='/PoliticaDePrivacidade'>Política de Privacidade</a></strong>"
                      />
                    </Col>
                  </Col>

                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="ACEITO"
                    checked={aceitoTermos}
                    onChange={(e) => setAceitoTermos(e.target.checked)}
                  />
                </Col>
                <Col lg={3}>
                  <Btn
                    type="secondary"
                    isSubmit={false}
                    text="Enviar"
                    onTouchable={handleSubmit}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={fecharToast}
      />
      {renderForm()}
    </>
  );
};
