import React, { useEffect, useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Col, Container, Row } from "react-bootstrap";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";
import Icons from "../../atoms/Icons";
import functions from "../../utils/functions";

import Paragraph from "../../atoms/Paragraph";

const CardMeioRecebimentoAdv = () => {
  const [dados, setDados] = useState([]);
  const id = sessionStorage.getItem("id");

  useEffect(() => {
    const api = new FinanceiroService();

    async function fetchDados() {
      try {
        const response = await api.exibirMeioRecebimento();
        setDados(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDados();
  }, []);

  const renderBancos = () => {
    return dados.Bancos.map((item, index) => (
      <Container >
        <Row key={`banco-${index}`} className=" my-3 box ">
          <Col className="d-flex flex-wrap  align-items-center ">
            <Col
              xs={12}
              
              lg={2}
              className="d-flex justify-content-center align-items-center"
            >
              <Col lg={6} xs={2} className="">
                <Icons type="banco" />
              </Col>
            </Col>

            <Col xs={12}  lg={5} className="text-start">
              <Paragraph
                text={
                  <>
                    <strong>Beneficiário:</strong> {item.nome}
                  </>
                }
              />
              <Paragraph
                text={
                  <>
                    <strong>Banco:</strong> {item.banco_nome}
                  </>
                }
              />
              <Paragraph
                text={
                  <>
                    <strong>Agência:</strong> {item.agencia}
                  </>
                }
              />
            </Col>
            <Col xs={12} sm={12} lg={5} className="text-start">
              {item.documento.length === 14 ? (
                <Paragraph
                  text={
                    <>
                      <strong>CNPJ:</strong>{" "}
                      {functions.formatarCNPJ(item.documento)}
                    </>
                  }
                />
              ) : (
                <Paragraph
                  text={
                    <>
                      <strong>CPF:</strong>{" "}
                      {functions.formatarCPF(item.documento)}
                    </>
                  }
                />
              )}
              <Paragraph
                text={
                  <>
                    <strong>Conta:</strong> {item.conta}
                  </>
                }
              />
            </Col>
          </Col>
        </Row>
      </Container>
    ));
  };

  const renderPixCards = () => {
    return dados.Pix.map((item, index) => (
      <Container>
        <Row key={`pix-${item.chave}`} className="my-3  box shadow-lg rounded">
          <Col className="d-flex my-2 gap-3 align-items-center mt-2 justify-content-start">
            <Col lg={2} className="text-center">
              <Icons type="pix" />
            </Col>
            <Col lg={10} className="d-flex row text-start gap-1">
              <Paragraph
                text={
                  <>
                    <strong>Nome:</strong> {item.nome}
                  </>
                }
              />
              <Paragraph
                text={
                  <>
                    <strong>Chave PIX:</strong> {item.chave}
                  </>
                }
              />
            </Col>
          </Col>
        </Row>
      </Container>
    ));
  };

  return (
    <Container lg={12} className="d-lg-flex gap-5 align-items-top">
      <Col lg={12} className="d-block ">
        {dados && dados.Bancos && dados.Bancos.length > 0
          ? renderBancos()
          : null}
      </Col>

      <Col lg={12} className="d-block ">
        {dados && dados.Pix && dados.Pix.length > 0 ? renderPixCards() : null}
      </Col>
    </Container>
  );
};

export default CardMeioRecebimentoAdv;
