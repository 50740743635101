import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import CardResultadoBuscaAdv from "../../molecules/CardResultadoBuscaAdv";
import { FormControl, Pagination } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import SearchFieldsHome from "../../molecules/SearchFieldsHome";
import Subtitle from "../../atoms/Subtitle";
import { Col, Row } from "react-bootstrap";
import { ImgModal } from "../../atoms/ImgModal";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { CloseOutlined } from "@ant-design/icons";

export const ResultadoSearch = () => {
  const location = useLocation();
  const { id } = useParams();

  const [resultado, setResultado] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [selectedGender, setSelectedGender] = useState(null);

  const resultsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchAndFilterResults = async () => {
      try {
        setFilteredResults([]);
        const data =
          location.state?.resultado ||
          (await new HomeService().buscarAdvogados());

        if (
          data.status !== true ||
          !data.data?.advogados ||
          data.data.advogados.length === 0
        ) {
          setFilteredResults([]);
          console.error("Erro na busca ou sem resultados:", data);
          return;
        }
        const advogados = data.data?.advogados || [];
        setResultado(data);

        let filteredResults = advogados;

        if (id && Array.isArray(advogados)) {
          const idToFilter =
            typeof advogados[0].advogado_id === "number" ? parseInt(id) : id;
          filteredResults = advogados.filter(
            (advogado) => advogado.cliente_adv_id === idToFilter
          );
        } else {
          const nomeFiltrado = advogados.filter(
            (advogado) =>
              advogado.cliente_nome
                .toLowerCase()
                .includes(filterName.toLowerCase().trim()) ||
              advogado.adv_cidade
                .toLowerCase()
                .includes(filterName.toLowerCase().trim())
          );

          let filteredByGender = nomeFiltrado;

          if (selectedGender) {
            filteredByGender = nomeFiltrado.filter(
              (advogado) => advogado.sexo === selectedGender
            );
          }

          filteredResults = filteredByGender || [];
        }

        setFilteredResults(filteredResults);
        setCurrentPage(1);
      } catch (error) {
        setFilteredResults([]);
        console.error("Erro durante o processo:", error);
      }
    };

    fetchAndFilterResults();
  }, [location.state, id, filterName, selectedGender]);

  const handleClearFilter = useCallback(() => {
    setFilterName("");
  }, []);

  const totalPages = Math.ceil(filteredResults.length / resultsPerPage);

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    return filteredResults
      .slice(startIndex, endIndex)
      .map((item) => (
        <CardResultadoBuscaAdv key={item.advogado_id} data={item} />
      ));
  };

  return (
    <div className="mt-5 pt-5">
      <Container fluid className="fluid-bg mb-5 pb-5">
        <Header />
      </Container>
      <Container className="mt-5 pt-5">
        <Row className="">
          <Col
            lg={12}
            className="w-100 pt-2 ps-lg-5 ms-lg-5 align-items-center justify-content-center"
          >
            <SearchFieldsHome />
          </Col>
        </Row>
        <Row className="fluid-bg w-100 mb-4 ps-lg-4 align-items-center justify-content-center">
          <Col lg={6} className="d-flex align-items-center m-0 p-0">
            <FormControl
              type="text"
              placeholder="Digite o nome ou cidade"
              className="form-control"
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
            />
            <CloseOutlined
              onClick={handleClearFilter}
              className="align-search-input anticon-close"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="">
            <Subtitle text="Resultado da busca" />
            {filteredResults.length === 0 ? (
              <Col className="m-5 text-center d-flex flex-column align-items-center">
                <Col>
                  <ImgModal type="resultado-busca" />
                </Col>
                <Col lg={5} className="my-3">
                  <Paragraph text="Parece que não foram encontrados resultados para a sua busca. Tente novamente mudando algum campo de busca." />
                </Col>
              </Col>
            ) : (
              renderTableRows()
            )}
            <Col className="my-3 gap-2 d-flex justify-content-center text-center">
              {totalPages > 1 && (
                <Pagination size="sm">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === currentPage}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )}
            </Col>
          </Col>
        </Row>
      </Container>
      <Container fluid className="fluid-bg">
        <Footer />
      </Container>
    </div>
  );
};

export default ResultadoSearch;
